import React, { useEffect, useState } from 'react';
import { makeStyles, useMediaQuery, useTheme, Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MainButton from './../MainButton';

const useStyles = makeStyles(() => ({
    container: {
        marginTop: '50px',
    },
    containerMobile: {
        marginTop: '40px',
    },
    titleText: {
        fontFamily: 'Titillium Web',
        fontSize: '40px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
        color: '#092F57',
    },
    mainText: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal',
        color: '#212121',
    },
}));

const CallToActionCard = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid container>
            <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                className={smDown ? classes.containerMobile : classes.container}>
                <Grid item container xs={10} direction="row" alignItems="center" className="">
                    <Grid item container xs={12} direction="column" className="">
                        <Grid item xs={12}>
                            <p className={classes.titleText}>{t(`${props.translationPrefix}.TITLE`)}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <p className={classes.mainText}>{t(`${props.translationPrefix}.MAIN_TEXT`)}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MainButton
                                onClick={() => {
                                    window.open(t(`${props.translationPrefix}.ACTION_URL`), '_blank');
                                }}
                                style={{ width: 'auto' }}>
                                {t(`${props.translationPrefix}.ACTION`)}
                            </MainButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CallToActionCard;
