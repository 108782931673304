import axiosInstance from './axios.config';

const RESTAPI_VERSION = '/v1';

const pinRequestService = {
    createRequest: function (cuit, phoneNumber, token) {
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/preCustomer/userInputVerification/sendcode`, {
            customerTaxId: cuit,
            userInputName: 'PtllaTelCelular',
            userInputValue: phoneNumber,
            token: token,
            origin: 'cc'
        });
    },
};

export default pinRequestService;
