import React from 'react';

export const obfuscate = (fullname) => {
    const names = fullname.trim().split(' ');
    var result = names[0].substring(0, 3) + names[0].substring(3).replace(/[a-zñA-ZÑ]/g, '*') + ' ';

    names.forEach((item, index) => {
        if (index > 0 && index < names.length - 1) {
            result += item.replace(/[a-zA-Z]/g, '*') + ' ';
        }
    });

    result +=
        names[names.length - 1].substring(0, names[names.length - 1].length - 3).replace(/[a-zA-Z]/g, '*') +
        names[names.length - 1].substring(names[names.length - 1].length - 3) +
        ' ';

    return result;
};

export const obfuscateFirstDigits = (chain, visibleChars = 4 ) => {
    if ( !chain || chain.length < visibleChars) {
        return chain;
    }

    const lastChars = chain.slice(-4);
    
    const asterisks = '*'.repeat(chain.length - 4);
    
    return asterisks + lastChars;
}