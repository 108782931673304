export const numberToCurrencyText = (value, decimalPlaces = 0) => {

    if (!value && value !== 0) {
        return '';
    }

    const numericValue = parseFloat(value);

    if (isNaN(numericValue)) {
        return '';
    }

    const result = numericValue
        .toLocaleString('es-ES', {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        });

    return '$' + result;
};

export const numberToPercentageText = (value, decimalPlaces = 2) => {
    if (!value) {
        return '';
    }
    return (
        value.toLocaleString('es-AR', {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        }) + '%'
    );
};

export const currencyFormatToNumber = (value) => {
    return parseFloat(
        value
            .replace(/[^\d.-]/g, '')
            .replace(/ /g, '')
            .replace(/\$/g, '')
            .replace(/\./g, '')
            .replace(/,/, '.')
    );
};
