import React, { useEffect } from 'react';
import workflowService from '../services/workflow.service';
import { useHistory } from 'react-router-dom';
import Loader from '../components/loader/Loader';
import creditCardRequestService from '../services/creditCardRequest.service';

export const Migration = (props) => {
    const { cobisId } = props.match.params;
    const history = useHistory();

    useEffect(() => {
        const getCurrentTask = async (hashKey) => {
            try {
                const currentTask = (await workflowService.getCurrentTask(hashKey)).data;
                history.push({ pathname: `/${currentTask.taskDefinitionKey}/${hashKey}`, search: history.location.search });
            } catch (error) {
                const { errorCode } = error.response.data;
                if (errorCode === 'NO_ACTIVE_TASKS') {
                    const creditCardRequest = (await creditCardRequestService.getCreditCardRequest(hashKey)).data;
                    switch (creditCardRequest.status) {
                        case 'REJECTED':
                            history.push({ pathname: `/rejected/${creditCardRequest.rejectionReasonCode}`, search: history.location.search });
                            break;
                        case 'FINALIZED':
                            history.push({ pathname: `/success/${hashKey}`, search: history.location.search });
                            break;
                        case 'READY_FOR_DELIVERY':
                            history.push({ pathname: `/success/${hashKey}`, search: history.location.search });
                            break;
                        case 'UNDER_REVIEW':
                            history.push({ pathname: `/success/${hashKey}`, search: history.location.search });
                            break;
                        default:
                            break;
                    }
                } else {
                    window.location = `${window.location.origin}/noRetryable/GENERAL`;
                }
            }
        }

        async function createMigrationCreditCard() {
            try {
                let response = await creditCardRequestService.createMigrationCreditCardRequest(`${cobisId}`);
                const creditCardRequest = response.data;
                getCurrentTask(creditCardRequest.hashKey);
            } catch (error) {
                history.push({ pathname: `/error`, search: history.location.search });
            }
        }

        createMigrationCreditCard();
    }, [cobisId]);

    return (
        <>
            <div>
                <Loader loading="true" />
            </div>
        </>
    );
};
