import React, { useState, useEffect } from 'react';
import { zonedTimeToUtc } from 'date-fns-tz';
import workflowService from '../../services/workflow.service';
import { CustomDatePicker } from '../customInputs/CustomDatePicker';
import { formatDate } from '../utils/dateUtils';

const DateUserInput = (props) => {
    const { conditioningValue, hashKey, stepName, userInput, onChange, onDisable } = props;
    const [value, setValue] = useState(userInput.userInputOptions.defaultValue || null);
    const [initialValue, setInitialValue] = useState(null);
    const userId = `ID_${userInput.userInputOptions.label}`;
    const DATE_NULL = "1901-01-01";

   useEffect(() => {
       if(value) {
           var stringDate = formatDate(value);
           if(stringDate > DATE_NULL) {
               onChange(userInput, value);
           } else {
               onChange(userInput, null);
           }
       }
    }, [value]);

    useEffect(() => {
        async function getData() {
            try {
                let dateData = (await workflowService.getUserInputData(hashKey, userInput.name, stepName, conditioningValue)).data;
                if (dateData === '1901-01-01') {
                    setValue(null);
                } else {
                    setValue(zonedTimeToUtc(dateData, 'America/Argentina/Buenos_Aires'));
                }
                setInitialValue(zonedTimeToUtc(dateData, 'America/Argentina/Buenos_Aires'));
            } catch (error) {
                console.error(error);
            }
        }
        if (conditioningValue) {
            getData();
        }
    }, [conditioningValue]);

    const handleDateChange = (date) => {
        setValue(date);
    };

    const hasToDisable = () => {
        if (onDisable) {
            return onDisable(value, initialValue);
        }
        return userInput.userInputOptions.disabled;
    };

    return (
        <CustomDatePicker
            style={{ marginTop: '0%', width: '100%' }}
            format="dd/MM/yyyy"
            margin="normal"
            id={userId}
            label={userInput.userInputOptions.label}
            value={value}
            errorHelperText="Complete la fecha con el formato dd/mm/aaaa"
            onChange={(date) => handleDateChange(date)}
            required={userInput.userInputOptions.required}
            KeyboardButtonProps={{
                'aria-label': 'Fecha',
            }}
            disabled={hasToDisable(value)}
        >
        </CustomDatePicker>
    );
};

export default DateUserInput;
