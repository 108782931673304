import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import '../../styles/components/stepperValidation.scss';

const getSteps = () => {
    return ['DNI frente', 'DNI dorso', 'Prueba de vida'];
};

export default function StepperValidation({ activeStep }) {
    const steps = getSteps();

    return (
        <Stepper nonLinear activeStep={activeStep} alternativeLabel style={{ paddingLeft: '0px', paddingBottom: '0px', backgroundColor: 'inherit' }}>
            {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                    <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>Paso {index}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
}
