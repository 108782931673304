import React, { useEffect, useState } from 'react';
import Tabs from '../components/login/tabs';
import '../styles/components/login.scss';
import MacroPage from './shared/MacroPage';
import { Grid } from '@material-ui/core';

export const Login = (props) => {
    const useWindowSize = () => {
        const [windowSize, setWindowSize] = useState({
            width: null,
            height: null,
        });

        useEffect(() => {
            function handleResize() {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }
            window.addEventListener('resize', handleResize);
            handleResize();
            return () => window.removeEventListener('resize', handleResize);
        }, []);
        return windowSize;
    };

    const size = useWindowSize();

    return (
        <>
            <MacroPage hideBackground={true}>
                <Grid container>
                    <Grid container item xs={12} md={12} className="banner-box">
                        <Grid item xs={11} sm={10} md={10} lg={8}>
                            <h1>
                                Estás a un paso de conocer <b>la oferta que tenemos para vos</b>
                            </h1>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={1} md={4}></Grid>
                        <Grid item xs={10} md={4} className="mt-2">
                            <Tabs {...props} className=""></Tabs>
                        </Grid>
                        <Grid item xs={1} md={4}></Grid>
                    </Grid>
                    <Grid item xs={12} className="footer mt-2">
                        BANCO MACRO S.A. CUIT 30-50001008-4-AV. EDUARDO MADERO N°1182, CABA
                    </Grid>
                </Grid>
            </MacroPage>
        </>
    );
};
