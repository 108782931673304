import TagManager from 'react-gtm-module';
import md5 from 'js-md5';
import { GTM_CODE, getEnv } from '../env';

const tagManagerArgs = {
    gtmId: `${getEnv(GTM_CODE)}`,
};

const KEY_CUSTOMER_ID = 'customerId';
const KEY_IS_CLIENT = 'isClient';
const KEY_TESTING_GROUP = 'testingGroup';
const KEY_CUSTOMER_EMAIL = 'customerEmail';


const getCommonEventProperties = () => {
    return {
        clientId: localStorage.getItem(KEY_CUSTOMER_ID),
        cd9: localStorage.getItem(KEY_IS_CLIENT),
        cd10: localStorage.getItem(KEY_CUSTOMER_EMAIL),
    };
};

const eventTrackingService = {
    initialize: () => {
        TagManager.initialize(tagManagerArgs);
    },
    getClientId: () => {
        return window.gaGlobal?.vid;
    },
    setCustomerId: (clientId) => {
        if (!clientId) {
            return;
        }
        const hashedClientId = md5(clientId).toUpperCase();
        localStorage.setItem(KEY_CUSTOMER_ID, hashedClientId);
        console.debug(`(${KEY_CUSTOMER_ID},${hashedClientId}) saved to local storage`);
    },
    setCustomerEmail: (customerEmail) => {
        if (!customerEmail) {
            return;
        }
        localStorage.setItem(KEY_CUSTOMER_EMAIL, customerEmail);
        console.debug(`(${KEY_CUSTOMER_EMAIL},${customerEmail}) saved to local storage`);
    },

    setIsClient: (isClient) => {
        if (!isClient) {
            return;
        }
        localStorage.setItem(KEY_IS_CLIENT, isClient);
        console.debug(`(${KEY_IS_CLIENT},${isClient}) saved to local storage`);
    },

    setTestingGroup: (testingGroup) => {
        if (!testingGroup) {
            return;
        }
        localStorage.setItem(KEY_TESTING_GROUP, testingGroup);
        console.debug(`(${KEY_TESTING_GROUP},${testingGroup}) saved to local storage`);
    },
    // eventname (mandatory): the key of the event
    // label (optional): tag for the event
    // value (optional): if used, must be numeric
    // step (optional): indicator for funnel step. By default it uses the path from the url
    // method (optional): metodo por el que se accedió al evento
    trackEvent: (eventName, label, value, step, method) => {
        const eventProperties = {
            event: 'interaction',
            action: eventName,
            label: label,
            value: value,
            step: step || window.location.pathname,
            'onboarding-method': method,
            ...getCommonEventProperties(),
        };
        window.dataLayer.push(eventProperties);
    },

    trackEventWithData: (eventName, data = {}, step, method) => {
        const eventProperties = {
            event: 'interaction',
            action: eventName,
            step: step || window.location.pathname,
            'onboarding-method': method,
            ...getCommonEventProperties(),
            ...data,
        };
        window.dataLayer.push(eventProperties);
    },

    // url: partial path of the call that failed
    // errorNumber: error code received either from backend or typicals (40X, 50X)
    // errorDescription: error description from browser o backend
    trackError: (url, errorNumber, errorDescription) => {
        const eventProperties = {
            event: 'error',
            action: 'error-service',
            target: url,
            targetProperties: errorDescription,
            value: errorNumber,
            ...getCommonEventProperties(),
        };
        window.dataLayer.push(eventProperties);
    },
    // errorNumber: error code received from TOC service
    // errorDescription: error description from browser o backend
    // instance: autocapture/liveness
    trackTocError: (errorNumber, errorDescription, instance) => {
        const eventProperties = {
            event: 'error',
            action: 'error-service',
            label: `toc_${errorNumber}`,
            target: instance,
            targetProperties: errorDescription,
            value: errorNumber,
            ...getCommonEventProperties(),
        };
        window.dataLayer.push(eventProperties);
    },
};

export default eventTrackingService;
