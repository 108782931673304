import axiosInstance from './axios.config';

const RESTAPI_VERSION = '/v1';

const documentService = {
    getDocuments: (hashKey, stepName) => {
        let result = axiosInstance.get(`${RESTAPI_VERSION}/workflow/creditcard/${hashKey}/documents/${stepName}`);

        return result;
    },
    uploadDocument: (hashKey, documentName, token, file) => {
        const form_Data = new FormData();
        form_Data.append('docFile', file, documentName + '.img');
        form_Data.append('currentUserUploadName', documentName);
        form_Data.append('tokenTOC', token.session_id);
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/creditcard/${hashKey}/userupload`, form_Data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    },
    getUploadToken: (hashKey) => {
        return axiosInstance.get(`${RESTAPI_VERSION}/toc/creditcard/${hashKey}/session-id`);
    },
    openDisclosureDocumentInNewTab: (hashKey) => {
        return axiosInstance({
            method: 'get',
            url: `${RESTAPI_VERSION}/workflow/creditcard/download/${hashKey}/documentation`,
            responseType: 'blob',
        });
    },
    openInstallmentPlanDocumentInNewTab: (hashKey) => {
        axiosInstance({
            method: 'get',
            url: `${RESTAPI_VERSION}/workflow/download/${hashKey}/installmentPlan`,
            responseType: 'blob',
        })
            .then(function (response) {
                var url = window.URL.createObjectURL(response.data);
                window.open(url);
            })
            .catch((err) => {
                console.error(err);
            });
    },
    identityValidation: (hashKey, documentName, token, file) => {
        const form_Data = new FormData();
        form_Data.append('docFile', file, documentName + '.img');
        form_Data.append('currentUserUploadName', documentName);
        form_Data.append('tokenTOC', token.session_id);

        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/creditcard/${hashKey}/identity-verification`, form_Data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    },
};

export default documentService;
