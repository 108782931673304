import axiosInstance from './axios.config';

const RESTAPI_VERSION = '/v1';

const offerService = {
    getAvailableProducts: (hashKey) => {
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/creditcard/${hashKey}/available-products`);
    },
    setSelectedProduct: (hashKey, selectedProductDto) => {
        return axiosInstance.put(`${RESTAPI_VERSION}/workflow/creditcard/${hashKey}/select-product`, selectedProductDto);
    },
};

export default offerService;
