import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import QRCode from 'qrcode';
import deviceCommunicationService from '../../services/deviceCommunication.service';

import { Box } from '@material-ui/core';

export const Qrcode = (props) => {
    const { hashKey } = props;
    const history = useHistory();

    useEffect(() => {
        async function generateQR() {
            try {
                let miniHashUrl = await deviceCommunicationService.getDocumentsUploadMiniUrl(hashKey, { urlParams: history.location.search });
                QRCode.toCanvas(document.getElementById('canvas'), miniHashUrl.data.url + '/orig/qr', function (error) {
                    if (error) console.error(error);
                });
            } catch (error) {
                console.log(error.response.data);
            }
        }
        if (hashKey) {
            generateQR();
        }
    }, []);

    return (
        <>
            <div style={{ zIndex: 100 }}>
                <Box style={{ backgroundColor: 'white', paddingBottom: 0, zIndex: 101 }}>
                    <canvas id="canvas" style={{ zIndex: 999 }} />
                </Box>
            </div>
        </>
    );
};
export default Qrcode;
