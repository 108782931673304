import React, { useEffect, useState } from 'react';
import MacroPage from './shared/MacroPage';
import { Button, Grid, Box } from '@material-ui/core';
import UserInputs from '../components/userInputs/UserInputs';
import useCurrentTask from '../components/bpm/useCurrentTask';
import './../styles/components/provinceSelection.scss';
import creditCardRequestService from '../services/creditCardRequest.service';
import useCreditCardRequest from '../hooks/useCreditCardRequest';

export const ProvinceSelection = (props) => {
    const { hashKey } = props.match.params;
    const { taskDefinitionKey } = props;
    const [validForm, setValidForm] = useState(false);
    const [userInputs, setUserInputs] = useState({});
    const { creditCardRequest } = useCreditCardRequest(hashKey);
    const task = useCurrentTask(hashKey, taskDefinitionKey);

    useEffect(() => {
        if (userInputs) {
            setValidForm(true);
        }
    }, [userInputs]);

    const handleNextPressed = async (event) => {
        event.preventDefault();
        try {
            await creditCardRequestService.setProvinceCode(hashKey, creditCardRequest.branchProvinceCode);

            task.completeTask();
        } catch (error) {
            console.error(error);
        }
    };

    const handleComplete = (userInputFilled) => {
        if (userInputFilled.PtllaProvincia) {
            creditCardRequest.branchProvinceCode = userInputFilled.PtllaProvincia;
            setUserInputs(userInputFilled);
        }
    };

    return (
        <MacroPage>
            <form onSubmit={handleNextPressed}>
                <Grid container direction="row" justify="center" alignItems="center" lg={12} sm={12} xs={12}>
                    <p className=" mt-3 fw-200 d-h3">Nos dirías en qué provincia radicarás tu préstamo?</p>
                    <div className="mt-3"></div>

                    <Grid container direction="column" justify="center" alignItems="left" lg={10} sm={12} xs={12}>
                        {creditCardRequest && <UserInputs stepName={taskDefinitionKey} hashKey={creditCardRequest.hashKey} onComplete={handleComplete} />}
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="center" xs={12}>
                        <Grid item xs={12}>
                            <Box className="mt-5"></Box>
                        </Grid>

                        <Grid item xs={12} sm={5} md={6} lg={4}>
                            <Button className="width-100-per" type="submit" variant="contained" color="primary" disabled={!validForm}>
                                Siguiente
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </MacroPage>
    );
};
