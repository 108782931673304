import { useState, useEffect } from 'react';
import creditCardRequestService from '../services/creditCardRequest.service';

const useCreditCardRequest = (hashKey) => {
    const [creditCardRequest, setCreditCardRequest] = useState(null);
    const [creditCardRequestError, setCreditCardRequestError] = useState(null);
    const [creditCardRequestLoading, setCreditCardRequestLoading] = useState(false);

    const getLoanRequest = async (hashKey) => {
        try {
            setCreditCardRequestLoading(true);
            setCreditCardRequest((await creditCardRequestService.getCreditCardRequest(hashKey)).data);
        } catch (error) {
            setCreditCardRequestError(error);
        } finally {
            setCreditCardRequestLoading(false);
        }
    };

    useEffect(() => {
        if (hashKey) {
            getLoanRequest(hashKey);
        }
    }, [hashKey]);

    return { creditCardRequest, creditCardRequestError, creditCardRequestLoading };
};

export default useCreditCardRequest;
