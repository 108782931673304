import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Grid, Button } from '@material-ui/core';
import documentService from '../services/document.service';
import useCurrentTask from '../components/bpm/useCurrentTask';
import '../styles/components/documentsUpload.scss';
import MacroStepPage from './shared/MacroStepPage';
import useCreditCardRequest from '../hooks/useCreditCardRequest';
import eventTrackingService from '../services/eventtracking.service';
import { DocumentUploader } from '../components/documentsUpload/DocumentUploader';
import redX from './../assets/bigX.jpg';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import { useTranslation } from 'react-i18next';
import Loader from './../components/loader/Loader';
import { DocumentsUploadHints } from './shared/DocumentsUploadHints';
import { calculateAge } from '../components/utils/dateUtils';

export const DocumentsUploadSelfie = (props) => {
    const { hashKey } = props.match.params;
    const { creditCardRequest } = useCreditCardRequest(hashKey);
    const [token, setToken] = useState(null);
    const [hasValidationErrors, setHasValidationErrors] = useState(false);
    const [errorCode, setErrorCode] = useState(null);
    const { t } = useTranslation();
    const analyticsStep = 8;
    const [documents, setDocuments] = useState(undefined);
    const [loading, setLoading] = useState(null);
    const task = useCurrentTask(hashKey, props.taskDefinitionKey);
    const [cardMigration, setCardMigration] = useState(false);

    useEffect(() => {
        async function getDocuments() {
            try {
                setLoading(true);
                setDocuments((await documentService.getDocuments(hashKey, 'documentsUpload')).data);
                setCardMigration(creditCardRequest.metadata.Origin === 'macro-migration-credit-card');
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        if (creditCardRequest) {
            getDocuments();
        }
    }, [creditCardRequest]);

    const onDocumentUploaded = (imgSrcUploaded) => {
        if (imgSrcUploaded) {
            eventTrackingService.trackEvent(
                'selfie',
                creditCardRequest.documentUploadAccessMethod === 'qr' ? 'con qr' : 'sin qr',
                null,
                analyticsStep
            );
        }
    };

    const handleNext = async () => {
        try {
            setLoading(true);

            eventTrackingService.trackEvent(
                'selfie completed',
                creditCardRequest.documentUploadAccessMethod === 'qr' ? 'con qr' : 'sin qr',
                null,
                analyticsStep
            );
            eventTrackingService.trackEventWithData(
                'tarjetas_validacion_id_exitosa',
                {
                    Segmento: creditCardRequest.metadata?.segment,
                    Subsegmento: creditCardRequest.metadata?.subSegment,
                    'Rango Etario': calculateAge(creditCardRequest.birthdate),
                    Género: creditCardRequest.gender || creditCardRequest.customerData.CustomerGender,
                },
                analyticsStep
            );

            await task.completeTask();
        } catch (error) {
            setHasValidationErrors(true);
            if (error.response) {
                if (error.response.data) {
                    setErrorCode(error.response.data.errorCode);
                } else {
                    console.error('Error: ' + error);
                }
            }
        } finally {
            setLoading(false);
        }
    };

    const handleRestartFaceValidation = () => {
        setToken(null);
        setHasValidationErrors(false);
    };

    const ShowValidationError = () => {
        return (
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid container item xs={12} direction="column" justifyContent="center" alignItems="center">
                    <div className="mt-5"></div>
                    <img src={redX} alt="Error" width="180px" />
                    <div className="mt-3"></div>
                    <p className="fs-18 fw-600">Hubo un problema</p>
                    <div className="mt-2"></div>
                    <p style={{ textAlign: 'center' }}>{t('RENAPER_VALIDATION_ERROR.' + errorCode + '.DESCRIPTION', 'Intente nuevamente')}</p>
                    <div className="mt-2"></div>
                    <Grid item container>
                        <div className="startButton center mt-2 mb-footer">
                            <Button onClick={handleRestartFaceValidation} className="width-100-per width-90-res" variant="contained" color="primary">
                                Siguiente
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const handleGetToken = (newToken) => {
        setToken(newToken);
    };

    return (
        <>
            <Loader loading={loading} />
            <MacroStepPage
                headerLead={cardMigration ? 'Estás muy cerca de tener tu nueva tarjeta' : 'Estás muy cerca de tener tu tarjeta de crédito'}
                headerLeadClassName="fw-600 lh-20"
                headerTitle="Realizá la prueba de vida"
                hashKey={hashKey}
                task={task}
                backToText="DNI Dorso"
                taskDefinitionKey={props.taskDefinitionKey}
                hideStepper={true}
                stepNumber={1}
                totalSteps={4}
                className="mt-1">
                {isMobile ? (
                    creditCardRequest && documents && !hasValidationErrors ? (
                        <>
                            <Loader loading={loading} />
                            <Grid item container style={{ zIndex: 100 }} xs={12} direction="row" justifyContent="center">
                                <DocumentUploader
                                    activeStep={2}
                                    title="Prueba de vida"
                                    hintInfo={<DocumentsUploadHints selfie />}
                                    hashKey={creditCardRequest.hashKey}
                                    document={documents[2]}
                                    previousToken={token}
                                    onGetToken={handleGetToken}
                                    onDocumentUploaded={onDocumentUploaded}
                                    onHandleNext={handleNext}
                                />
                            </Grid>
                        </>
                    ) : (
                        hasValidationErrors && <ShowValidationError />
                    )
                ) : (
                    <ContinueMobile hashKey={hashKey} />
                )}
            </MacroStepPage>
        </>
    );
};
