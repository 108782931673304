import React, { useEffect, useState } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TermsModal from './TermsModal';
import termService from '../../services/term.service';

const AcceptGeneralTermsCheckbox = (props) => {
    const [accepted, setAccepted] = useState(false);
    const [terms, setTerms] = useState(null);
    const [termsVisible, setTermsVisible] = useState(false);

    useEffect(() => {
        getTerms();
    }, []);

    const getTerms = async () => {
        try {
            const terms = (await termService.getTerms('macro-credit-card')).data;
            setTerms(terms);
        } catch (error) {
            console.log(error);
        }
    };

    const handleAcceptChange = (event) => {
        setAccepted(event.target.checked);
        props.onChange(event.target.checked, terms.id);
    };

    const handleTermsAccepted = () => {
        setAccepted(true);
        setTermsVisible(false);
        props.onChange(true, terms.id);
    };

    const handleTermsRejected = () => {
        setAccepted(false);
        setTermsVisible(false);
        props.onChange(false, terms.id);
    };

    const handleTermsClick = (event) => {
        event.preventDefault();
        setTermsVisible(true);
    };

    const label = (
        <span className="fs-14">
            Leí y acepto los {" "}<a href="#" onClick={handleTermsClick}>Términos y Condiciones</a>
        </span>
    );

    if (!terms) {
        return null;
    }
    return (
        <div className="flex center mt-1">
        <FormGroup row>
            <TermsModal open={termsVisible} termsUrl={terms.url} onReject={handleTermsRejected} onAccept={handleTermsAccepted} />
            <FormControlLabel control={<Checkbox checked={accepted} onChange={handleAcceptChange} name="accepted" color="primary" />} label={label} />
        </FormGroup>
        </div>
    );
};

export default AcceptGeneralTermsCheckbox;
