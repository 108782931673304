import React from 'react';
import { numberToCurrencyText } from "./numberUtils";


export const fechaInicioBonificacion = (selectedProductDetails) => {
  if (selectedProductDetails && selectedProductDetails.length > 0) {
    const bonificationDate = selectedProductDetails.find(
      (detail) => detail.code === 'Fecha_bonificacion_inicio'
    );

    return bonificationDate ? bonificationDate.value : "";
  }

    return "";
  };

  export const fechaFinBonificacion = (selectedProductDetails) => {
    if (selectedProductDetails && selectedProductDetails.length > 0) {
      const bonificationDate = selectedProductDetails.find(
        (detail) => detail.code === 'Fecha_bonificacion_fin'
      );
  
      return bonificationDate ? bonificationDate.value : "";
    }
  
    return "";
  };

export const precioPaquete = (selectedProductDetails) => selectedProductDetails ? numberToCurrencyText(selectedProductDetails.filter((detail) => detail.code === 'Precio_paquete')[0].value) : null;

export const montoBonificado100 =  (selectedProductDetails) => selectedProductDetails ? numberToCurrencyText(selectedProductDetails.filter((detail) => detail.code === 'Monto_bonificacion_100')[0].value) : null;

export const montoBonificado50 =  (selectedProductDetails) => selectedProductDetails ? numberToCurrencyText(selectedProductDetails.filter((detail) => detail.code === 'Monto_bonificacion_50')[0].value) : null;

export const footnote = (selectedProduct) => (<>
    {selectedProduct && selectedProduct.productCode === 'INTERNACIONAL' && (
      `(1) Se suman los saldos diarios de las cuentas  asociadas al servicio y se divide por la cantidad de días del mes. `
    )}
    {selectedProduct && selectedProduct.productCode === 'GOLD' && (
      `(1) Se suman los saldos diarios de las cuentas  asociadas al servicio y se divide por la cantidad de días del mes. `
    )}
    {selectedProduct && selectedProduct.productCode === 'PLATINUM' && (
       `(1) Se suman los saldos diarios de las cuentas  asociadas al servicio y se divide por la cantidad de días del mes y/o el saldo de plazo fijo 
       vigentes a fin de mes.`
    )}
    {selectedProduct && selectedProduct.productCode === 'BLACK' && (
        `(1) Se suman los saldos diarios de las cuentas  asociadas al servicio y se divide por la cantidad de días del mes y/o el saldo de plazo fijo
        vigentes a fin de mes.`
    )}
    </>);

export const showTerms = (selectedProduct, selectedProductDetails, packageName) => (<p> 
                    {selectedProduct && selectedProduct.productCode === 'INTERNACIONAL' && (
                        `(1) Bonificación de servicio: (1.1) válido para quienes contraten un servicio Macro Valora Esencial, Macro Valora Superior, Macro Preferencial, 
                        y Macro Selecta del ${fechaInicioBonificacion(selectedProductDetails)} al ${fechaFinBonificacion(selectedProductDetails)}. 100% de bonificación en la comisión de mantenimiento sobre el servicio y por el plazo de doce
                         meses desde el alta del producto. A partir del mes trece aplicará el costo de comisión por servicio mensual vigente al momento del cobro. 
                         Ver comisiones en macro.com.ar (1.2) bonificación del 100% de la comisión por servicio mensual durante tres meses adicionales y continuos al beneficio. 
                         (1.1) exclusivamente para quienes registren como mínimo tres adhesiones a débitos automáticos por cuenta, en comercios registrados en la República Argentina con la/s tarjeta/s de 
                         crédito Visa y/o American Express asociada al servicio abierto según condiciones 
                         (1.1). A partir del mes dieciséis aplicará el costo de comisión por servicio mensual vigente al momento del cobro. Válido con las Tarjetas de Crédito Visa,
                          Mastercard y American Express asociadas a un servicio de Banco Macro, a excepción de tarjetas de crédito asociadas al servicio de 
                          productos Macro Valora Inicial, para los cuales no aplicará comisiones en macro.com.ar. 
                         (2) Condiciones vigentes desde el ${fechaInicioBonificacion(selectedProductDetails)} hasta el ${fechaFinBonificacion(selectedProductDetails)}. Bonificación de la comisión mensual por servicio de mantenimiento de cuenta, 
                         en caso que se registren saldos mensuales en la/s cuenta/s a la vista de servicios ${packageName}, de acuerdo con lo siguiente: 
                         50% de bonificación en caso de saldos promedios mensuales iguales o superiores a ${montoBonificado50(selectedProductDetails)}; 100% de bonificación en caso de saldos promedio mensuales iguales 
                         o superiores a ${montoBonificado100(selectedProductDetails)}. El/los Saldo/s que se considerarán al último día hábil del mes a analizar serán: (i) Saldo promedio de cuenta/s a la vista
                         pertenecientes a servicio ${packageName} (se considera Saldo Promedio al saldo que resulta de la suma del saldo diario registrado en la cuenta en el mes a analizar,
                         dividido por la cantidad de días de dicho mes)`
                    )}
                    {selectedProduct && selectedProduct.productCode === 'GOLD' && (
                        `(1) Bonificación de servicio: (1.1) válido para quienes contraten un servicio Macro Valora Esencial, Macro Valora Superior, Macro Preferencial, 
                        y Macro Selecta del ${fechaInicioBonificacion(selectedProductDetails)} al ${fechaFinBonificacion(selectedProductDetails)}. 100% de bonificación en la comisión de mantenimiento sobre el servicio y por el plazo de doce meses desde el alta del producto.
                         A partir del mes trece aplicará el costo de comisión por servicio mensual vigente al momento del cobro. Ver comisiones en macro.com.ar (1.2) bonificación del 100% 
                         de la comisión por servicio mensual durante tres meses adicionales y continuos al beneficio. (1.1) exclusivamente para quienes registren como mínimo tres adhesiones 
                         a débitos automáticos por cuenta, en comercios registrados en la República Argentina con la/s tarjeta/s de crédito Visa y/o American Express asociada al servicio abierto 
                         según condiciones (1.1). A partir del mes dieciséis aplicará el costo de comisión por servicio mensual vigente al momento del cobro. Válido con las Tarjetas de Crédito Visa,
                         Mastercard y American Express asociadas a un servicio de Banco Macro, a excepción de tarjetas de crédito asociadas al servicio Macro Valora Inicial,
                         para los cuales no aplicará comisiones en macro.com.ar. (2) Condiciones vigentes desde el ${fechaInicioBonificacion(selectedProductDetails)} hasta el ${fechaFinBonificacion(selectedProductDetails)}. Bonificación de la comisión mensual por
                         servicio de mantenimiento de cuenta, en caso que se registren saldos mensuales en la/s cuenta/s a la vista de servicios ${packageName}, de acuerdo con lo siguiente: 50% 
                         de bonificación en caso de saldos promedios mensuales iguales o superiores a ${montoBonificado50(selectedProductDetails)}; 100% de bonificación en caso de saldos promedio mensuales iguales o
                         superiores a ${montoBonificado100(selectedProductDetails)}. El/los Saldo/s que se considerarán al último día hábil del mes a analizar serán: (i) Saldo promedio de cuenta/s a la vista
                         pertenecientes a servicio ${packageName} (se considera Saldo Promedio al saldo que resulta de la suma del saldo diario registrado en la cuenta en el mes a analizar, 
                         dividido por la cantidad de días de dicho mes)`
                    )}
                    {selectedProduct && selectedProduct.productCode === 'PLATINUM' && (
                        `(1) Bonificación de servicio: (1.1) válido para quienes contraten un servicio Macro Valora Esencial, Macro Valora Superior, Macro Preferencial
                        y Macro Selecta del ${fechaInicioBonificacion(selectedProductDetails)} al ${fechaFinBonificacion(selectedProductDetails)}. 100% de bonificación en la comisión de mantenimiento sobre el servicio y por el plazo de doce meses 
                        desde el alta del producto. A partir del mes trece aplicará el costo de comisión por servicio mensual vigente al momento del cobro. Ver comisiones en macro.com.ar 
                        (1.2) bonificación del 100% de la comisión por servicio mensual durante tres meses adicionales y continuos al beneficio. (1.1) exclusivamente para quienes registren 
                        como mínimo tres adhesiones a débitos automáticos por cuenta, en comercios registrados en la República Argentina con la/s tarjeta/s de crédito Visa y/o American Express 
                        asociada al servicio abierto según condiciones (1.1). A partir del mes dieciséis aplicará el costo de comisión por servicio mensual vigente al momento del cobro. 
                        Válido con las Tarjetas de Crédito Visa, Mastercard y American Express asociadas a un servicio de Banco Macro, a excepción de tarjetas de crédito asociadas 
                        al servicio Macro Valora Inicial, para los cuales no aplicará comisiones en macro.com.ar. (2) Condiciones vigentes desde el ${fechaInicioBonificacion(selectedProductDetails)} hasta el ${fechaFinBonificacion(selectedProductDetails)}. 
                        Bonificación de la comisión mensual por servicio de mantenimiento de cuenta, en caso que se registren saldos mensuales en la/s cuenta/s a la vista de 
                        servicios ${packageName} y/o depósito/s a plazo fijo de titularidad del cliente, de acuerdo con lo siguiente: 50% de bonificación en caso de saldos promedios mensuales 
                        iguales o superiores a ${montoBonificado50(selectedProductDetails)}; 100% de bonificación en caso de saldos promedio mensuales iguales o superiores a ${montoBonificado100(selectedProductDetails)}. 
                        En caso que el Cliente tenga vigente bajo su titularidad, en el mes de análisis, cuentas a la vista de servicios ${packageName} y depósitos a plazo fijo, 
                        los saldos se considerarán de manera conjunta; para ello, los depósitos a plazo fijo deben encontrarse vigentes al cierre de dicho mes. El/los Saldo/s que se considerarán 
                        al último día hábil del mes a analizar serán: (i) Saldo promedio de cuenta/s a la vista pertenecientes a servicio ${packageName} (se considera Saldo Promedio al saldo 
                        que resulta de la suma del saldo diario registrado en la cuenta en el mes a analizar, dividido por la cantidad de días de dicho mes); (ii) Saldo correspondiente a plazo/s fijo/s 
                        vigentes al cierre de dicho período. Los saldos en dólares serán convertidos a pesos considerando la cotización del B.C.R.A. al último día hábil del mes a analizar. 
                        No acumulable con otra/s bonificación/es que apliquen sobre el mismo servicio.`
                    )}
                    {selectedProduct && selectedProduct.productCode === 'BLACK' && (
                      `(1) Bonificación de servicio: (1.1) válido para quienes contraten un servicio Macro Valora Esencial, Macro Valora Superior, Macro Preferencial y Macro Selecta
                        del ${fechaInicioBonificacion(selectedProductDetails)} al ${fechaFinBonificacion(selectedProductDetails)}. 100% de bonificación en la comisión de mantenimiento sobre el servicio y por el plazo de doce meses desde el alta del producto.
                        A partir del mes trece aplicará el costo de comisión por servicio mensual vigente al momento del cobro. Ver comisiones en macro.com.ar (1.2) bonificación del 100% de
                        la comisión por servicio mensual durante tres meses adicionales y continuos al beneficio. (1.1) exclusivamente para quienes registren como mínimo tres adhesiones a débitos
                        automáticos por cuenta, en comercios registrados en la República Argentina con la/s tarjeta/s de crédito Visa y/o American Express asociada al servicio abierto
                         según condiciones (1.1). A partir del mes dieciséis aplicará el costo de comisión por servicio mensual vigente al momento del cobro. 
                         Válido con las Tarjetas de Crédito Visa, Mastercard y American Express asociadas a un servicio de Banco Macro, a excepción de tarjetas de crédito asociadas al Servicio
                         de productos Macro Valora Inicial, para los cuales no aplicará comisiones en macro.com.ar. (2) Condiciones vigentes desde el ${fechaInicioBonificacion(selectedProductDetails)} hasta el ${fechaFinBonificacion(selectedProductDetails)}. 
                        Bonificación de la comisión mensual por servicio de mantenimiento de cuenta, en caso que se registren saldos mensuales en la/s cuenta/s a la vista de servicios ${packageName} 
                        y/o depósito/s a plazo fijo de titularidad del cliente, de acuerdo con lo siguiente: 50% de bonificación en caso de saldos promedios mensuales iguales o superiores a ${montoBonificado50(selectedProductDetails)};
                         100% de bonificación en caso de saldos promedio mensuales iguales o superiores a ${montoBonificado100(selectedProductDetails)}. En caso que el Cliente tenga vigente bajo su titularidad, en el mes de análisis,
                         cuentas a la vista de servicios ${packageName} y depósitos a plazo fijo, los saldos se considerarán de manera conjunta; para ello, los depósitos a plazo fijo deben encontrarse vigentes
                         al cierre de dicho mes. El/los Saldo/s que se considerarán al último día hábil del mes a analizar serán: (i) Saldo promedio de cuenta/s a la vista pertenecientes a Servicio ${packageName} 
                         (se considera Saldo Promedio al saldo que resulta de la suma del saldo diario registrado en la cuenta en el mes a analizar, dividido por la cantidad de días de dicho mes); 
                        (ii) Saldo correspondiente a plazo/s fijo/s vigentes al cierre de dicho período. Los saldos en dólares serán convertidos a pesos considerando la cotización del B.C.R.A. al 
                        último día hábil del mes a analizar.  No acumulable con otra/s bonificación/es que apliquen sobre el mismo servicio.`
                    )}
                   </p>);

export const showMigrationTerms = () => (  
                  <>
                    <p className="dialog-title-conditions">
                      Manifestacion preliminar 
                    </p>  
                    <p className="disclaimer-note">
                      Cartera de Consumo. Sujeto a condiciones de contratación de Banco Macro S.A. CUIT 30-50001008-4, Av. Eduardo Madero N*1.182, CABA
                    </p>
                    <p className="dialog-title-conditions">
                      Vigencia 
                    </p>                      
                    <p className="disclaimer-note">
                      La vigencia es del 01 de Febrero de 2024 al 23 de Marzo del 2024. 
                    </p>
                    <p className="dialog-title-conditions">
                      ¿Quiénes pueden participar?
                    </p>                      
                    <p className="disclaimer-note">
                      Podrán participar todos los clientes de Banco Macro destinatarios de la presente pieza que al momento de la vigencia del programa cuenten con una Tarjeta de Crédito 
                      correspondiente al  Segmento Cartera General o Macro Selecta, activa en Banco Macro a nivel personal sin mora en ninguno de sus productos.
                    </p>
                    <br/>
                    <p className="dialog-title-conditions">
                      Condiciones de otorgamiento 
                    </p>     
                    <p className="dialog-title-conditions">
                      Cartera General/ Cartera Macro Selecta
                    </p>     
                    <p className="disclaimer-note">
                      Para acceder el cliente deberá calificar crediticiamente para dar de alta una nueva Tarjeta de Crédito American Express/ American Express ICON Macro Selecta ( según corresponda)
                       solicitar y suscribir la documentación correspondiente para el alta de la misma. Asimismo, será condición esencial:
                       <ol style={{"list-style-type": "lower-roman"}}>
                          <li>
                            <p>
                              La baja de al menos 1 (una) tarjeta de crédito activa preexistente para acceder a la promoción. El detalle de la tarjeta de crédito a dar de baja, 
                              será brindado por el  proceso de otorgamiento digital al momento de dar de alta la nueva tarjeta de crédito..
                            </p>
                            <p>
                              Aceptada la baja de la tarjeta de crédito preexistente, la baja no implicara caída de cuotas de los consumos realizados hasta el día de la fecha. Asimismo, 
                              el resumen seguirá llegando hasta que se abone el último saldo sin costo alguno e impacte la baja definitiva.. 
                            </p>

                          </li>
                          <li>
                            Efectuada el alta de la nueva Tarjeta de Crédito American Express/ American Express ICON Macro Selecta ,según calificación crediticia,  el Cliente deberá realizar al menos 1 (un) consumo de $5.000 o más, dentro de los primeros 60 días desde el alta de la Tarjeta de Crédito.
                          </li>
                       </ol>
                      De cumplirse con todas  las condiciones requeridas anteriormente, el Banco procederá a acreditar la cantidad de 4.000 Puntos Macro Premia 
                      de obsequio en la cuenta de Macro Premia a partir de los 60 (sesenta) días de realizado el consumo indicado en el párrafo anterior.
                    </p>
                    <br/>
                    <p className="dialog-title-conditions">
                      Aplicación de los Beneficios
                    </p>     
                    <p className="dialog-title-conditions">
                      Puntos Macro Premia Y Macro Premia Volar  Millas
                    </p>     
                    <p className="disclaimer-note">
                      El Programa Macro Premia(“Macro Premia”) es una propuesta exclusiva para los clientes (los “Cliente/s”)  de Banco Macro (“Banco Macro”) por 
                      el que podrán adquirir una amplia gama de productos, servicios y/o beneficios (los “Beneficios”), mediante el canje de puntos Macro Premia 
                      (“Puntos Macro Premia”), canje de Puntos Macro Premia más pesos, o bajo la modalidad solo Pesos (“Full Pesos”).
                    </p>                   
                    <p className="disclaimer-note">
                      Fórmula de Cálculo: La acumulación de Puntos por consumos con Tarjetas de Crédito de Banco Macro. $/US$ 600 (pesos o dólares) de consumo = 1 
                      Punto Macro Premia al 31/03/2024. Se encuentran excluidas de Macro Premia las Tarjetas de Crédito de Banca Empresas, Agro, Tarjetas de Débito.
                    </p>                   
                    <p className="disclaimer-note">
                      La solicitud de Puntos Macro Premia según el Programa de Millas seleccionado será válida al 31/03/2024 inclusive. Sin mínimo o máximo exigido para 
                      la solicitud.La validación de la solicitud estará sujeta a la disponibilidad de puntos por parte del cliente que los quiere convertir y habilitación del socio del Programa de Millas seleccionado.
                    </p>                   
                    <p className="disclaimer-note">
                      Para recibir las Millas el cliente deberá ser simultáneamente socio del Programa e indicar la identificación de la cuenta de la cual es titular para 
                      la acreditación de las Millas provenientes de la solicitud que rige bajo las condiciones del Programa de Millas Seleccionado.
                    </p>                   
                    <p className="disclaimer-note">
                      La transferencia de puntos Macro Premia a Millas según el Programa seleccionado no extiende la vigencia de las mismas.
                    </p>                   
                    <p className="disclaimer-note">
                      La solicitud de Puntos Macro Premia por Millas no podrá ser anulada luego de su confirmación aprobación por parte del Programa seleccionado. 
                      La no utilización de las Millas o la asignación equívoca de las mismas por instrucción del cliente no habilita el reintegro de los Puntos. 
                    </p> 
                    <br/>     
                    <p className="dialog-title-conditions">
                      Programas de millas participantes “MACRO PREMIA VOLAR MILLAS”
                    </p>      
                    <ol style={{"list-style-type": "lower-roman"}} className="disclaimer-note">
                      <li>
                        El programa Aerolíneas Plus pertenece a Aerolíneas Argentinas, único responsable de la correcta administración de las millas acumuladas por el titular.
                         El socio deberá ingresar a Aerolíneas Argentinas para informarse acerca del proceso de reserva de pasajes, disponibilidad y condiciones de los mismos. 
                         Aerolíneas Argentinas S.A.: Rafael Obligado S/N, Terminal 4, 6to Piso, Aeroparque Jorge Newbery – CABA - CUIT 30-64140555-4. Los términos y condiciones 
                         del programa Aerolíneas Plus son propiedad de Aerolíneas Argentinas S.A. y se encuentran disponibles en www.aerolineas.- com.ar o en el teléfono 0810-222-86527.
                      </li>
                      <li>
                        El programa SMILES pertenece a SMILES FIDELIDADE ARGENTINA S.A. , único responsable de la correcta administración de las millas acumuladas por el titular. 
                        El socio deberá ingresar a SMILES para informarse acerca del proceso de reserva de pasajes, disponibilidad y condiciones de los mismos. 
                        SMILES FIDELIDADE ARGENTINA S.A. Dirección Cerrito 1186, piso 8 CABA - CUIT 30-71625556-1. Los términos y condiciones del programa SMILES son 
                        propiedad de SMILES FIDELIDADE ARGENTINA S.A. y se encuentran disponibles en www.smiles.com.ar.
                      </li>
                      <li>
                        El programa LATAM Pass pertenece a LATAM Airlines Group S.A., único responsable de la correcta administración de las millas acumuladas por el titular. 
                        El socio deberá ingresar a LATAM Airlines Group S.A. para informarse acerca del proceso de reserva de pasajes, disponibilidad y condiciones de los mismos.
                         LATAM Airlines Group S.A.: , con domicilio en Cerrito 866, piso 2, Ciudad Autónoma de Buenos Aires - CUIT- 30-7162555-6. Los términos y condiciones del programa 
                         LATAM Pass son propiedad de LATAM Airlines Group S.A. y se encuentran disponibles en www.[http://latampass.latam.com/es_ar/](Acumulá, canjeá y disfrutá todos los 
                         beneficios | LATAM Pass "smartCard-inline"). Air Europa suma
                      </li>
                      <li>
                        El programa Air Europa Suma pertenece a Air Europa Lineas Aereas S.L.U., único responsable de la correcta administración de las Millas acumuladas por el titular. 
                        El socio deberá ingresar a Air Europa Suma para informarse acerca del proceso de reserva de pasajes, disponibilidad y condiciones de los mismos. Air Europa Suma 
                        Miles S.L.U. Dirección Carretera Arenal-Llucmajor, km. 21'5. 07620, Llucmajor (Islas Baleares, España). Los términos y condiciones del programa Air Europa Suma 
                        son propiedad de Air Europa Suma Miles S.L.U y se encuentran disponibles en https://www.aireuropa.com/ar/es/aea/suma/nuestro-programa/condiciones-generales.html.
                      </li>
                    </ol>
                    <p className="disclaimer-note">
                      Los servicios prestados por la Empresas propietarias de los Programas seleccionados, así como la operación del programa de fidelización de clientes es de exclusiva
                       responsabilidad de la Empresa y los mismos no son garantizados ni promocionados por Banco Macro. Válido del 01/02/2024 al 31/03/2024 con tarjetas de crédito Visa y 
                       débito.
                    </p>            
                    <br/>
                    <p className="dialog-title-conditions" style={{"color": "red"}}> 
                      MODO
                    </p>     
                    <p className="disclaimer-note" style={{"color": "red"}}>
                      La aplicación del descuento del 20% con MODO será aplicable a clientes usuarios registrados en MODO, exclusivamente para las primeras 10 compras que se realicen en pagos presenciales u on line con MODO aplicando un tope del 20% por transacción y un tope por cuenta por mes de $10.000. El reintegro se realizará en el momento de la compra en la caja de ahorro o cuenta corriente de Banco Macro registrada en MODO. Para que aplique el beneficio, el pago a comercios debe ser realizado con tarjetas de credito o debito Visa de Banco Macro y tener cuenta abierta en Banco Macro asociada a MODO.
                    </p>
                    <br/>
                    <p className="dialog-title-conditions"> 
                      Condiciones aplicables a los beneficios
                    </p>     
                    <p className="disclaimer-note">
                      Los beneficios sólo se aplicarán  para consumo personal/familiar. No será válido para tarjeta empresa/agro. No se podrán acumular con otras promociones. 
                      No aplicaran para pagos realizados a través de Mercado Pago/Pagos electrónicos inmediatos/ Transferencias 3.0. Establecimientos, productos y servicios no son 
                      promocionados/ garantizados por Banco Macro. Más información en macro.com.ar o al 0810-555-2355.
                    </p>
                    <br/>
                    <p className="dialog-title-conditions"> 
                      Aspectos generales
                    </p>
                    <p className="disclaimer-note">
                      En todos los casos, previo a la obtención de la Tarjeta de Crédito American Express/ American Express ICON Macro Selecta, 
                      el Cliente deberá satisfacer todo los requisitos comerciales y legales vigentes y quedará a criterio de Banco Macro el otorgamiento del producto.
                    </p>

                  </>);