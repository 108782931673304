import React, { useState } from 'react';
import { FormControl} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const CustomTypeahead = ({ children, required, fullWidth, onChange, onInvalid, helperText, errorHelperText, ...props }) => {
    const [error, setError] = useState(false);
    const { options } = props;
    const [noMatchingInputString, setNoMatchingInputString] = useState(null);

    const handleInvalid = (event) => {
        event.preventDefault();
        if (onInvalid) {
            onInvalid(event);
        }
        setError(true);
        console.log({error})
    };

    const handleInputChange = (event, newValue) => {
        if(!newValue) { 
            return;
        }
        const valueToMatch = newValue.toUpperCase();
        const matchedOption = options.find(option => option.descripcion.toUpperCase().includes(valueToMatch));
        if (!matchedOption) {
            if(!valueToMatch.startsWith(noMatchingInputString)) {
                setNoMatchingInputString(valueToMatch);

                if(props.onAnalyticsEvent) {
                    props.onAnalyticsEvent(props.name, "EMPTY_OPTIONS_LIST",valueToMatch);
                }
            }
            
            setError(true);
        } else {
            setNoMatchingInputString(null);
            setError(false);
        }
        
    }

    const handleChange = (...args) => {
        if (onChange) {
            onChange(...args);
        }
        setError(false);
    };

    return (
        <FormControl 
            fullWidth={fullWidth} 
            required={required}
        >
            <Autocomplete
                onInvalid={handleInvalid}
                onChange={handleChange}
                onInputChange={handleInputChange}
                error={error}
                renderInput={(params) => React.isValidElement(children) && React.cloneElement(children, {
                    ...params,
                    error: error,
                    helperText: error ? errorHelperText : helperText
                })}
                {...props}
            />
        </FormControl>
    );
};
