import React, { useState, useEffect } from 'react';
import workflowService from '../../services/workflow.service';
import { TextField } from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { CustomTypeahead } from '../customInputs/CustomTypeahead';
import { UNDEFINED_VALUE } from './userInputConstants';
import Loader from '../loader/Loader';
import { useTranslation } from 'react-i18next';
import Toaster from '../../components/alerts/Toaster';

const OPTIONS_LIMIT = 10;

const prepareComboList = (comboData) => {
    return comboData;
};

const TypeaheadUserInput = (props) => {
    const { conditioningValue, hashKey, stepName, userInput, onChange } = props;
    const [comboList, setComboList] = useState(conditioningValue ? [] : prepareComboList(userInput.userInputOptions.comboData));
    const [value, setValue] = useState(null);
    const [defaultValue, setDefaultValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [toasterOpen, setToasterOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (!userInput) {
            return;
        }
        if (userInput.userInputOptions.defaultValue !== defaultValue) {
            setDefaultValue(userInput.userInputOptions.defaultValue);
        }
    }, [userInput]);

    useEffect(() => {
        if (!defaultValue) {
            return;
        }
        if (comboList) {
            comboList.forEach((item) => {
                if (item.id === defaultValue) {
                    setValue(item);
                    setDefaultValue(null);
                }
            });
        }
    }, [defaultValue]);

    useEffect(() => {
        async function getComboData() {
            try {
                setLoading(true);
                let comboData = (await workflowService.getUserInputComboData(hashKey, userInput.name, stepName, conditioningValue)).data;
                setComboList(prepareComboList(comboData));
                setLoading(false);
            } catch (error) {
                setLoading(false);
                let errorCode = 'UNKNOWN_ERROR';
                if (error.response && error.response.data) {
                    errorCode = error.response.data.errorCode;
                }
                showErrorOnToaster(errorCode);
            }
        }
        if (conditioningValue) {
            getComboData();
        }
    }, [conditioningValue]);

    useEffect(() => {
        if (value && value !== UNDEFINED_VALUE) {
            onChange(userInput, value.id);
        }
    }, [value]);

    useEffect(() => {
        setValue(null);
        if (comboList.length === 1) {
            setValue(comboList[0]);
        } else {
            comboList.forEach((item) => {
                if (item.selected || item.id == defaultValue) {
                    setValue(item);
                    setDefaultValue(null);
                }
            });
        }
    }, [comboList]);

    const handleComboChange = (event, value, reason) => {
        if (value && value.id) {
            setValue(value);
            setDefaultValue(null);
        }
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setToasterOpen(false);
    };

    const showErrorOnToaster = (errorCode) => {
        const UNKNOWN_ERROR_KEY = 'WORKFLOW_ERROR.UNKNOWN_ERROR.DESCRIPTION';
        let translationKey = `WORKFLOW_ERROR.${errorCode}.DESCRIPTION`;
        let translation = t(translationKey);
        if (translation !== translationKey) {
            setErrorMessage(translation);
        } else {
            setErrorMessage(t(UNKNOWN_ERROR_KEY));
        }
        setToasterOpen(true);
    };

    return (
        <>
            <Loader loading={loading} />
            <ShowToaster open={toasterOpen} textToShow={errorMessage} type="error" handleToasterClose={handleToasterClose} />
            {comboList && comboList.length > 0 && (
                <CustomTypeahead
                    fullWidth={true}
                    required={userInput.userInputOptions.required}
                    autoSelect={true}
                    disableClearable={true}
                    disabled={userInput.userInputOptions.disabled}
                    disableListWrap
                    name={userInput.name}
                    options={comboList}
                    filterOptions={createFilterOptions({
                        limit: OPTIONS_LIMIT,
                        ignoreAccents: false,
                    })}
                    getOptionLabel={(comboItem) => {
                        if (!comboItem) {
                            return '';
                        }
                        return comboItem.descripcion;
                    }}
                    getOptionSelected={(option, value) => value === option.id}
                    onChange={handleComboChange}
                    onAnalyticsEvent={props.onAnalyticsEvent}
                    value={value}
                    errorHelperText="Requerido"
                    helperText={userInput.userInputOptions.hint}>
                    <TextField
                        label={userInput.userInputOptions.label}
                        required={userInput.userInputOptions.required}
                        disabled={userInput.userInputOptions.disabled}
                    />
                </CustomTypeahead>
            )}
        </>
    );
};

export default TypeaheadUserInput;
