export const UNDEFINED_VALUE = '';
export const UNDEFINED_ITEM = { descripcion: 'Seleccione alguna de las opciones', id: '' };
export const TRUE_VALUE = 'true';
export const FALSE_VALUE = 'false';
export const VARIABLE_NAMES = {
    ADDRESS_STREET_NAME: 'PtllaCalle',
    ADDRESS_STREET_NUMBER: 'PtllaAltura',
    ADDRESS_UNIT_FLOOR: 'PtllaPiso',
    ADDRESS_UNIT_NUMBER: 'PtllaDepto',
    ADDRESS_PROVINCE: 'PtllaProvincia',
    ADDRESS_CITY: 'PtllaLocalidad',
    ADDRESS_POSTAL_CODE: 'PtllaCodPostal',
    ADDRESS_NEIGHBORHOOD: 'PtllaBarrio',
};
export const NORMALIZATION_STATUS = {
    OK: 'CO',
    ALTERNATIVES: 'DU',
};