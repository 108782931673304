import {convertDateFormat} from './dateUtils';

export const buildNpsUserInformation = (creditCardRequest) => {
    var user = {
                "user.entityMis"       : creditCardRequest.customerData.cobisId || null,
                "user.nombre"          : creditCardRequest.customerData.CustomerFirstName,
                "user.apellido"        : creditCardRequest.customerData.CustomerLastName,
                "user.email"           : creditCardRequest.customerData.CustomerMail,
                "user.celular"         : "+54" + creditCardRequest.customerData.CustomerCellphone,
                "user.fechaNacimiento" : creditCardRequest.customerData.CustomerBirthdate.substring(0, 10),
                "user.genero"          : creditCardRequest.customerData.CustomerGender || null,
                "user.transaccion.numeroSolicitud" : creditCardRequest.publicId,
                "user.transaccion.fechaSolicitud"  : convertDateFormat(creditCardRequest.createdDate),
                "user.transaccion.nombreOferta"    : creditCardRequest.offerSelected.Name || null,
                "user.transaccion.nombreMarcaTC"   : creditCardRequest.offerSelected.OfferBrandName || null,
                "user.transaccion.nombrePaquete"   : creditCardRequest.offerSelected.Nombre_paquete || null,
                "user.transaccion.codigoCartera"   : creditCardRequest.offerSelected.Cartera || null,
                "user.transaccion.codigoProducto"  : creditCardRequest.offerSelected.productCode || null,
                "user.transaccion.limiteOferta"    : creditCardRequest.offerSelected.OfferLimit || null,
                "user.transaccion.utm_source"      : creditCardRequest.marketingData.utm_source || null,
                "user.transaccion.utm_medium"      : creditCardRequest.marketingData.utm_medium || null,
                "user.transaccion.utm_campaign"    : creditCardRequest.marketingData.utm_campaign || null,
                "user.transaccion.utm_content"     : creditCardRequest.marketingData.utm_content || null
        };

        return user;    

};