import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createTheme } from '@material-ui/core';

const dropdownIconNode = <ExpandMoreIcon style={{ color: '#4a4a4a' }} />;

const dropdownIconComponent = () => {
    return dropdownIconNode;
};

const Theme = createTheme({
    palette: {
        primary: {
            main: '#FF4452',
        },
        secondary: {
            main: '#203AE9',
        },
        terms: {
            main: '#00000',
        },
    },
    button: {
        height: '300px',
    },
    props: {
        MuiSelect: {
            IconComponent: dropdownIconComponent,
        },
        MuiAutocomplete: {
            popupIcon: dropdownIconNode,
        },
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: '30px',
                fontWeight: 'bolder',
                textTransform: 'initial',
                fontSize: '1.3em',
            },
            outlinedPrimary: {
                border: '2px solid #FF4452',
            },
        },
        MuiInput: {
            underline: {
                '&::after': {
                    borderBottomColor: '#203AE9'
                }
            }
        },
        MuiFormLabel: {
            root: {
                '&$focused': {
                    color: '#203AE9',
                }
            }
        }
    }
});

export default Theme;
