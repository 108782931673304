import React from 'react';
import UseLogin from './components/login/useLogin';
import { Login } from './pages/LoginPage';
import { OfferSelection } from './pages/OfferSelectionPage';
import { DocumentsUploadSelfie } from './pages/documentsUploadSelfie';
import { PersonalAddressDataInput } from './pages/PersonalAddressDataInputPage';
import { DisclosurePage } from './pages/DisclosurePage';
import { Rejected } from './pages/rejected/RejectedPage';
import { SessionExpired } from './pages/sessionExpiredPage';
import { IdentityValidation } from './pages/IdentityValidationPage';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ErrorPage } from './pages/ErrorPage';
import { CustomerCreditCardRequestSuccessPage } from './pages/CustomerCreditCardRequestSuccessPage';
import { RedirectFromMinihash } from './components/deviceCommunication/redirectFromMinihash';
import { MinifiedHashPage } from './pages/MinifiedHashPage';
import { ProvinceSelection } from './pages/ProvinceSelectionPage';
import { CustomerAfipData } from './pages/CustomerAfipDataPage';
import eventTrackingService from './services/eventtracking.service';
import { OnBoardingNotAvailable } from './pages/OnBoardingNotAvailable';
import { SelectOfficeForAccountOpening } from './pages/SelectOfficeForAccountOpeningPage';
import { DeliveryInfoInput } from './pages/DeliveryInfoInputPage';
import { BranchOfficeInfoInput } from './pages/BranchOfficeInfoPage';
import { AffidavitDataInput } from './pages/AffidavitDataInputPage';
import { DocumentsUploadFront } from './pages/documentsUploadFrontPage';
import { DocumentsUploadBack } from './pages/documentsUploadBackPage';
import { RestartManualDocumentsUpload } from './pages/RestartManualDocumentsUpload';
import Faq from './components/faq/faq';
import { API_URL, SALESFORCE_BEACON_SCRIPT_URL, HOTJAR_ENABLED, getEnv } from './env';
import { StepPage } from './pages/StepPage';
import AppPasswordCreationPage from './pages/AppPasswordCreationPage';
import ValidateEmailPage from './pages/ValidateEmailPage';
import { Helmet } from 'react-helmet';
import { NoRetryable } from './pages/NoRetryablePage';
import { Migration } from './pages/Migration';

eventTrackingService.initialize();

const RESTAPI_URL = `${getEnv(API_URL)}`;
const RESTAPI_VERSION = 'v1';

export default function App() {
    return (
        <Router>
            <Switch>
                <Route
                    path="/IdentityValidation/:hashKey"
                    render={(props) => <IdentityValidation {...props} taskDefinitionKey="IdentityValidation" title="IdentityValidation" />}
                />{' '}
                <Route
                    path="/MacroTcIdentityVerification/:hashKey"
                    render={(props) => <StepPage {...props} taskDefinitionKey="MacroTcIdentityVerification" title="MacroTcIdentityVerification" />}
                />{' '}
                <Route
                    path="/MacroTcOfferSelection/:hashKey"
                    render={(props) => <OfferSelection {...props} taskDefinitionKey="MacroTcOfferSelection" />}
                />{' '}
                <Route
                    path="/MacroTcDocumentsUploadFront/:hashKey"
                    render={(props) => <DocumentsUploadFront {...props} taskDefinitionKey="MacroTcDocumentsUploadFront" />}
                />{' '}
                <Route
                    path="/MacroTcDocumentsUploadBack/:hashKey"
                    render={(props) => <DocumentsUploadBack {...props} taskDefinitionKey="MacroTcDocumentsUploadBack" />}
                />{' '}
                <Route
                    path="/MacroTcDocumentsUploadSelfie/:hashKey"
                    render={(props) => <DocumentsUploadSelfie {...props} taskDefinitionKey="MacroTcDocumentsUploadSelfie" />}
                />{' '}
                <Route
                    path="/MacroTcRestartDocumentsUpload/:hashKey"
                    render={(props) => <RestartManualDocumentsUpload {...props} taskDefinitionKey="MacroTcRestartDocumentsUpload" />}
                />{' '}
                <Route path="/cc/:minHash/orig/:orig" render={(props) => <RedirectFromMinihash {...props} />} />{' '}
                <Route path="/cc/:minHash" render={(props) => <RedirectFromMinihash {...props} />} />{' '}
                <Route path="/o/:hashId" render={(props) => <MinifiedHashPage {...props} />} />{' '}
                <Route
                    path="/st/:minHash"
                    component={(props) => {
                        window.location.href = `${RESTAPI_URL}${RESTAPI_VERSION}/documents/terms/${props.match.params.minHash}`;
                        return null;
                    }}
                />
                <Route
                    path="/MacroTcPersonalAddressData/:hashKey"
                    render={(props) => <PersonalAddressDataInput {...props} taskDefinitionKey="MacroTcPersonalAddressData" />}
                />{' '}
                <Route
                    path="/MacroTcAffidavitDataInput/:hashKey"
                    render={(props) => <AffidavitDataInput {...props} taskDefinitionKey="MacroTcAffidavitDataInput" />}
                />{' '}
                <Route
                    path="/MacroTCDeliveryInfoInput/:hashKey"
                    render={(props) => <DeliveryInfoInput {...props} taskDefinitionKey="MacroTCDeliveryInfoInput" />}
                />{' '}
                <Route
                    path="/MacroTCBranchOfficeInfoInput/:hashKey"
                    render={(props) => <BranchOfficeInfoInput {...props} taskDefinitionKey="MacroTCBranchOfficeInfoInput" />}
                />{' '}
                <Route
                    path="/MacroTcSelectOfficeForAccountOpening/:hashKey"
                    render={(props) => <SelectOfficeForAccountOpening {...props} taskDefinitionKey="MacroTcSelectOfficeForAccountOpening" />}
                />
                <Route
                    path="/MacroTcAditionalCustomerData/:hashKey"
                    render={(props) => <CustomerAfipData {...props} taskDefinitionKey="MacroTcAditionalCustomerData" />}
                />{' '}
                <Route
                    path="/MacroTcEmailAddressVerification/:hashKey"
                    render={(props) => <ValidateEmailPage {...props} taskDefinitionKey="MacroTcEmailAddressVerification" />}
                />{' '}
                <Route path="/MacroTcDisclosure/:hashKey" render={(props) => <DisclosurePage {...props} taskDefinitionKey="MacroTcDisclosure" />} />
                <Route path="/rejected/:rejectedReasonCode" render={(props) => <Rejected {...props} />} />
                <Route path="/error" render={(props) => <ErrorPage {...props} />} />
                <Route path="/sessionExpired" render={(props) => <SessionExpired {...props} />} />
                <Route path="/faqs" render={(props) => <Faq full={true} {...props} />} />
                <Route path="/faq/:hashKey/:taskDefinitionKey" render={(props) => <Faq full={true} {...props} />} />
                <Route path="/success/:hashKey" render={(props) => <CustomerCreditCardRequestSuccessPage {...props} />} />
                <Route path="/customerIdentification" render={(props) => <Home {...props} />} />
                <Route path="/onBoardingNotAvailable/:fromHour/:toHour" render={(props) => <OnBoardingNotAvailable {...props} />} />
                <Route
                    path="/provinceSelection/:hashKey"
                    render={(props) => <ProvinceSelection {...props} taskDefinitionKey="provinceSelection" />}
                />{' '}
                <Route
                    path="/MacroTcAppPasswordCreation/:hashKey"
                    render={(props) => <AppPasswordCreationPage {...props} taskDefinitionKey="MacroTcAppPasswordCreation" />}
                />{' '}
                <Route
                    path="/migration/:cobisId"
                    render={(props) => <Migration {...props} />}
                />{' '}
                <Route
                    path="/noRetryable/:errorText"
                    render={(props) => <NoRetryable {...props} />}
                />{' '}
                <Route path="/sessionExpired" render={(props) => <SessionExpired {...props} />} />{' '}
                <Route path="/" render={(props) => <Home {...props} />} /> <Route path="/faq" render={(props) => <ErrorPage {...props} />} />{' '}
                <Route
                    path="/manualAutorization/:hashKey"
                    // render={(props) => <CustomerLoanRequestSuccessPage {...props} />}
                    render={(props) => <StepPage {...props} taskDefinitionKey="manualAutorization" title="manualAutorization" />}
                />{' '}
            </Switch>{' '}
        </Router>
    );
}

function Home(props) {
    return (
        <UseLogin>
            <Login {...props} />
            <Helmet>{getEnv(HOTJAR_ENABLED) && <script type="text/javascript" src="/hotjar.js" />}</Helmet>
            <Helmet>{getEnv(SALESFORCE_BEACON_SCRIPT_URL) && <script type="text/javascript" src={getEnv(SALESFORCE_BEACON_SCRIPT_URL)} />}</Helmet>
        </UseLogin>
    );
}
