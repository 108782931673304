import axiosInstance from './axios.config';

const RESTAPI_VERSION = '/v1';

const productService = {
    getProductByModel: (productModel) => {
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/product/${productModel}`);
    },
};

export default productService;
