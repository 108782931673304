import React, { useState, useEffect, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { Box, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import '../../styles/components/shared/macroPage.scss';
import NavBarSocialMedia from './../../components/navBar/NavBarSocialMedia';
import Loading from '../../components/loader/Loader';
import CallToActionCard from './../../components/shared/CallToActionCard';
import { SALESFORCE_BEACON_SCRIPT_URL, getEnv } from './../../env';

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: null,
        height: null,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
}

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

const MacroPage = (props) => {
    const size = useWindowSize();
    const width = size.width;
    const marginTopDevice = width > 600 ? '72px' : '50px';
    const backgroundClassName = props.callToAction?.backgroundClassName || 'backgroundStyle';
    const backgroundClassNameMobile = props.callToAction?.backgroundClassNameMobile || 'backgroundStyle';
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    ScrollToTop();

    return (
        <>
            <Helmet>{getEnv(SALESFORCE_BEACON_SCRIPT_URL) && <script type="text/javascript" src={getEnv(SALESFORCE_BEACON_SCRIPT_URL)} />}</Helmet>
            <Suspense fallback={<Loading />}>
                <Grid container direction="row" justifyContent="center">
                    <Grid item xs={12}>
                        <NavBarSocialMedia />
                        <Box className={props.hideBackground ? '' : 'assetBg'}></Box>
                    </Grid>
                    {!smDown && props.callToAction && (
                        <Grid
                            height="100%"
                            item
                            md={6}
                            style={{ marginTop: `${marginTopDevice}`, minHeight: '100vh', overflow: 'hidden', backgroundColor: 'black' }}
                            className={backgroundClassName}>
                            <Box alt="">{props.callToAction && <CallToActionCard {...props.callToAction} />}</Box>
                        </Grid>
                    )}
                    <Grid item md={!smDown && props.callToAction ? 6 : 12} style={{ marginTop: `${marginTopDevice}` }}>
                        {props.children}
                    </Grid>
                    {props.callToAction && smDown && (
                        <Grid item xs={12} style={{ width: '100%', height: '50vh' }}>
                            <Box className={backgroundClassNameMobile} style={{ minHeight: '90vh' }}>
                                <CallToActionCard {...props.callToAction} />
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Suspense>
        </>
    );
};

export default MacroPage;
