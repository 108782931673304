import axiosInstance from './axios.config';

const RESTAPI_VERSION = '/v1';

const workflowService = {
    getUserInputComboData: function (hashKey, userInputName, stepName, conditioningValue) {
        const params = {
            hash: hashKey,
            stepName: stepName,
            conditioningValue: conditioningValue,
            userInputName: userInputName,
        };
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/userinput/combodata`, { params: params });
    },
    getUserInputData: function (hashKey, userInputName, stepName, conditioningValue) {
        const params = {
            hash: hashKey,
            stepName: stepName,
            conditioningValue: conditioningValue,
            userInputName: userInputName,
        };
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/userinput/data`, { params: params });
    },

    getUserInputFields: function (hashKey, stepName) {
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/creditcard/${hashKey}/resolveuserinputs/${stepName}`);
    },

    getCurrentTask: function (hashKey) {
        return axiosInstance.get(`/v1/workflow/creditcard/task/${hashKey}/current`);
    },

    completeTask: function (hashKey, task) {
        return axiosInstance.post(`/v1/workflow/creditcard/task/${hashKey}/complete`, task);
    },
    getLoanRequestProduct: function (hashKey) {
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/creditcard/${hashKey}/product`);
    },
    triggerTaskEvent: function (hashKey, triggerConfig) {
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/creditcard/task/${hashKey}/trigger-event`, triggerConfig);
    },
    getFAQ: (task) => {
        const params = {
            page: task,
            section: task,
        };
        return axiosInstance.get(`${RESTAPI_VERSION}/faqs/`, { params: params });
    },
    canStartRequest: () => {
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/workingDay`);
    },
    sendEmailVerificationCode: function (hash) {
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/creditcard/${hash}/email-verification-codes`);
    },

};

export default workflowService;
