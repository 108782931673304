import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import workflowService from '../../services/workflow.service';
import eventTrackingService from '../../services/eventtracking.service';
import creditCardRequestService from '../../services/creditCardRequest.service';
import { CREDIT_CARD_REQUEST_STATUS, ERROR, ROUTE } from './../../constants/global';

/**
 * Makes sure that the given task is actually the current task (otherwise it
 * redirects to the proper task) and returns a callback function that signals
 * task completion. When the callback is invoked, the hook will attempt to
 * mark it as completed in the backend and redirect to the next task.
 * This custom hook should be used by any top level component that handles
 * a user task.
 * @param {string} hashKey The hash key associated to the workflow
 * @param {string} taskDefinitionKey The task key that the component handles
 * @returns {function} A callback function that signals current task completion
 * @throws Will throw an error if the task completed callback wall invoked but the
 *         task could not be completed
 */
const useCurrentTask = (hashKey, taskDefinitionKey) => {
    const [task, setTask] = useState(null);
    const [taskCompleted, setTaskCompleted] = useState(false);
    const [historyState, setHistoryState] = useState(null); // state to (optionally) pass in a history push
    const history = useHistory();

    useEffect(() => {
        let currentTask = null;
        async function getCurrentTask() {
            try {
                currentTask = (await workflowService.getCurrentTask(hashKey)).data;

                if (currentTask.taskDefinitionKey !== taskDefinitionKey) {
                    history.push({
                        pathname: `/${currentTask.taskDefinitionKey}/${hashKey}`,
                        search: history.location.search,
                        state: historyState,
                    });
                } else {
                    setTask(currentTask);
                }
            } catch (error) {
                if (error.response) {
                    const { errorCode } = error.response.data;
                    if (ERROR.NO_ACTIVE_TASKS === errorCode) {
                        const creditCardRequest = (await creditCardRequestService.getCreditCardRequest(hashKey)).data;
                        switch (creditCardRequest.status) {
                            case CREDIT_CARD_REQUEST_STATUS.REJECTED:
                                history.push({
                                    pathname: `${ROUTE.REJECTED}/${creditCardRequest.rejectionReasonCode}`,
                                    search: history.location.search,
                                });
                                return;
                            case CREDIT_CARD_REQUEST_STATUS.EXPIRED:
                                history.push({
                                    pathname: `${ROUTE.ERROR_NON_RETRYABLE}/${creditCardRequest.status}`,
                                    search: history.location.search,
                                });
                                return;
                            case CREDIT_CARD_REQUEST_STATUS.FINALIZED:
                            case CREDIT_CARD_REQUEST_STATUS.READY_FOR_DELIVERY:
                            case CREDIT_CARD_REQUEST_STATUS.UNDER_REVIEW:
                                history.push({ pathname: `${ROUTE.SUCCESS}/${hashKey}`, search: history.location.search });
                                return;
                            default:
                                if (creditCardRequest.metadata.onboardingCompleted == 'true') {
                                    history.push({ pathname: `${ROUTE.SUCCESS}/${hashKey}`, search: history.location.search });
                                } else {
                                    history.push({
                                        pathname: `${ROUTE.ERROR_NON_RETRYABLE}/${ERROR.UNKNOWN}`,
                                        search: history.location.search,
                                    });
                                }
                                return;
                        }
                    }
                }
                if (error.response.status == 403) {
                    history.push({ pathname: `${ROUTE.ERROR_SESSION_EXPIRED}`, search: history.location.search });
                    return;
                }
                history.push({
                    pathname: `${ROUTE.ERROR_NON_RETRYABLE}/${ERROR.UNKNOWN}`,
                    search: history.location.search,
                });
            }
        }

        if (hashKey) {
            getCurrentTask();
        }
    }, [hashKey, taskCompleted]);

    const getAnalyticsStepNumber = (taskName) => {
        var opts = {
            documentsType: 5,
            documentsUploadFront: 6,
            documentsUploadBack: 7,
            documentsUploadSelfie: 8,
            offerSelection: 9,
            customerDataInputDirect: 10,
            disclosure: 11,
        };

        return opts[taskName] ? opts[taskName] : 0;
    };

    const completeTask = async (options) => {
        try {
            const actualTaskName = `${task.taskDefinitionKey}_completed`;

            await workflowService.completeTask(hashKey, task);

            // track event to analytics
            eventTrackingService.trackEvent(actualTaskName, null, null, getAnalyticsStepNumber(task.taskDefinitionKey));
            if (options && options.state) {
                setHistoryState(options.state);
            } else {
                // Clear the state set by a previous completeTask call if the current completeTask doesn't specify a state.
                // Otherwise the next history push (when getting the current task) would send that previous state along.
                setHistoryState(null);
            }
            setTaskCompleted(true);
        } catch (error) {
            if (error.response && error.response.data) {
                const { errorCode } = error.response.data;
                if (errorCode === 'CREDIT_CARD_REQUEST_EXPIRED') {
                    history.push({ pathname: `/`, search: history.location.search });
                } else {
                    const creditCardRequest = (await creditCardRequestService.getCreditCardRequest(hashKey)).data;
                    if (creditCardRequest.status === 'REJECTED') {
                        history.push({ pathname: `/rejected/${creditCardRequest.rejectionReasonCode}`, search: history.location.search });
                    }
                }
            }
            throw error;
        }
    };

    const goToPreviousTask = async () => {
        if (
            (!task.availableEventNames || task.availableEventNames.length === 0) &&
            (!task.parentAvailableEventNames || task.parentAvailableEventNames.length === 0)
        ) {
            console.error(`No hay tarea a la que volver desde ${task.actualTaskName}`);
            return;
        }
        const triggerConfig = {
            processInstanceId:
                task.availableEventNames && task.availableEventNames.length > 0 ? task.processInstanceId : task.parentProcessInstanceId,
            eventName:
                task.availableEventNames && task.availableEventNames.length > 0 ? task.availableEventNames[0] : task.parentAvailableEventNames[0],
        };
        try {
            const nextTask = (await workflowService.triggerTaskEvent(hashKey, triggerConfig)).data;
            history.push({ pathname: `/${nextTask.taskDefinitionKey}/${hashKey}`, search: history.location.search });
        } catch (error) {
            const creditCardRequest = (await creditCardRequestService.getCreditCardRequest(hashKey)).data;
            if (creditCardRequest.status === 'REJECTED') {
                history.push({ pathname: `/rejected/${creditCardRequest.rejectionReasonCode}`, search: history.location.search });
            } else {
                history.push({ pathname: `/error`, search: history.location.search });
            }
        }
    };

    const goToTask = async (eventName) => {
        if (!task.availableEventNames || !task.availableEventNames.filter((e) => e === eventName)) {
            console.error('No existe evento: ' + eventName);
            return;
        }
        let availableEventNames = task.availableEventNames.filter((e) => e === eventName)[0];
        const triggerConfig = {
            processInstanceId: task.processInstanceId,
            eventName: availableEventNames,
        };
        try {
            const nextTask = (await workflowService.triggerTaskEvent(hashKey, triggerConfig)).data;
            history.push({ pathname: `/${nextTask.taskDefinitionKey}/${hashKey}`, search: history.location.search });
        } catch (error) {
            const ccRequest = (await creditCardRequestService.getCreditCardRequest(hashKey)).data;
            if (ccRequest.status === 'REJECTED') {
                history.push({ pathname: `/rejected/${ccRequest.rejectionReasonCode}`, search: history.location.search });
            } else {
                history.push({ pathname: `/error`, search: history.location.search });
            }
        }
    };

    const hasEvents = () => {
        return task !== null && (task.availableEventNames.length > 0 || task.parentAvailableEventNames.length > 0);
    };

    const getTaskDefinitionKey = () => {
        if (!task) {
            return null;
        }
        return task.taskDefinitionKey;
    };

    return {
        completeTask: completeTask,
        goToPreviousTask: goToPreviousTask,
        goToTask: goToTask,
        hasEvents: hasEvents,
        getTaskDefinitionKey: getTaskDefinitionKey,
    };
};

export default useCurrentTask;
