import React, { useContext, useState, useEffect } from 'react';
import { ApiContext } from '../useLogin';
import pinRequestService from '../../../services/pinRequest.service';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { Grid, Box, Button, TextField } from '@material-ui/core';
import eventTrackingService from '../../../services/eventtracking.service';
import { GOOGLE_RECAPTCHA_KEY, getEnv } from '../../../env';

import Toaster from '../../alerts/Toaster';
import { useTranslation } from 'react-i18next';
import Recaptcha from 'react-google-invisible-recaptcha';
import ReactCodeInput from 'react-code-input';

const TextMaskCustom = (props) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            // showMask
        />
    );
};

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const MAX_WAIT_TIME = 60;

export const CheckPhone = (props) => {
    const { handlePinSent } = props;
    const { request, number, setNumber, pin, setPin } = useContext(ApiContext);
    const [seconds, setSeconds] = useState(MAX_WAIT_TIME);

    const [sendCode, setSendCode] = useState(props.sendCode);

    const [showPhoneInput, setShowPhoneInput] = useState(true);
    const [validationCodeSentSms, setValidationCodeSentSms] = useState(false);
    const [retryCodeSentSms, setRetryCodeSentSms] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const mediaMatch = window.matchMedia('(min-width: 500px)');
    const [matches, setMatches] = useState(mediaMatch.matches);
    const [token, setToken] = useState(null);
    // Error handling
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    // Analytics
    const analyticsStep = 2;
    // captcha handling
    const CAPTCHA_KEY = getEnv(GOOGLE_RECAPTCHA_KEY);
    let recaptcha = React.useRef(null);
    // cuit handling
    const { clientNumber } = number;
    const { cuit } = request;
    const [secret, setSecret] = useState(null);
    const [showManualInputMessage, setShowManualInputMessage] = useState(false);

    useEffect(() => {
        if (token) {
            handleSendPin(token);
        }
    }, [token]);

    useEffect(() => {
        const handler = (e) => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
    });

    const styles = {
        container: (isFullWidth) => ({
            marginTop: isFullWidth ? '20px' : '20px',
            minWidth: isFullWidth ? '90%' : '100%',
            marginLeft: isFullWidth ? '10px' : '0px',
            maxWidth: '100%',
            width: isFullWidth ? '90%' : '100%',
        }),
    };

    const handleChangeNumber = (e) => {
        let phoneNumber = e.target.value.trim();
        setValidationCodeSentSms(false);
        setNumber({
            [e.target.name]: e.target.value.trim(),
            ...setNumber,
            clientNumber: phoneNumber,
        });

        setPhoneError(false);
    };

    const handleChangePin = (value) => {
        setPin({
            ...setPin,
            clientPin: value,
        });
    };

    useEffect(() => {
        if (number) {
            setValidationCodeSentSms(false);
            setNumber({
                phone: number.clientNumber,
                ...setNumber,
                clientNumber: number.clientNumber,
            });
            setPhoneError(false);
        }

        setPin({
            ...setPin,
            clientPin: null,
        });

        if (sendCode) {
            validateCaptcha();
        }
    }, []);

    useEffect(() => {
        if (seconds <= 0) {
            setRetryCodeSentSms(true);
            setValidationCodeSentSms(false);
            setSeconds(MAX_WAIT_TIME);
        }
    }, [seconds]);

    useEffect(() => {
        if (validationCodeSentSms) {
            const timer = setInterval(() => {
                setSeconds(seconds - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    });

    const onResolved = (tkn) => {
        setToken(tkn);
    };

    const validateCaptcha = () => {
        if (clientNumber.length >= 10 && !/^15/.test(clientNumber)) {
            if (getEnv(GOOGLE_RECAPTCHA_KEY)) {
                recaptcha.execute();
            } else {
                handleSendPin(null);
            }
        } else {
            setPhoneError(true);
        }
    };
    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const handleSendPin = (tkn) => {
        eventTrackingService.trackEvent('enviar código de validación sms_tc', null, null, analyticsStep);

        pinRequestService
            .createRequest(`${cuit}`, `${clientNumber}`, tkn)
            .then(function (pinResponse) {
                if (pinResponse.data) {
                    setPin({ clientPin: pinResponse.data });
                    setSecret(`[${pinResponse.data}]`);
                } else {
                    setPin({ clientPin: '' });
                    setSecret('');
                }
                setValidationCodeSentSms(true);
                setShowPhoneInput(false);
                setShowManualInputMessage(false);
                handlePinSent(true);
            })
            .catch(function (error) {
                setPin({ clientPin: '' });
                setSecret('');
                setShowPhoneInput(true);
                setShowManualInputMessage(false);
                setValidationCodeSentSms(false);
                handlePinSent(false);
                if (error.response && error.response.data) {
                    setMessage(t(`WORKFLOW_ERROR.${error.response.data.errorCode}.DESCRIPTION`) || 'Error al enviar SMS');
                } else {
                    setMessage(error.message);
                }
                setOpen(true);
            });
    };

    const handleCelChangeClicked = () => {
        setValidationCodeSentSms(false);
        setShowPhoneInput(true);
        handlePinSent(false);
        setSeconds(MAX_WAIT_TIME);

        setNumber({
            ...setNumber,
            clientNumber: null,
        });

        setPin({
            ...setPin,
            clientPin: null,
        });
    };

    return (
        <Box style={{ minHeight: '240px' }}>
            {' '}
            {(validationCodeSentSms || !showPhoneInput) && (
                <>
                    <Box className="mb-2 ">
                        <strong className="fs-14 fw-600"> Celular {clientNumber} - </strong>{' '}
                        <a href="#" className="link fs-14 fw-400 color-link-blue" onClick={handleCelChangeClicked}>
                            Editar{' '}
                        </a>{' '}
                    </Box>
                </>
            )}{' '}
            <Grid container direction="column">
                <Box className=""> </Box>{' '}
                {showPhoneInput && (
                    <p className=" fw-400 mt-1"> Ingresá tu número de celular. Te enviaremos un SMS con un código de 4 dígitos para validarlo. </p>
                )}{' '}
                <Grid item container direction="row" align="center" className=" ">
                    <Grid item xs={12} sm={6} className="">
                        {' '}
                        {showPhoneInput && (
                            <TextField
                                style={{ marginTop: '10px', width: '100%' }}
                                id="cell"
                                label={clientNumber ? '' : 'Celular'}
                                onChange={handleChangeNumber}
                                name="phone"
                                value={clientNumber}
                                error={phoneError}
                                helperText={phoneError ? 'Formato de celular inválido. Volvé a ingresarlo' : 'Sin el 0 ni el 15. Ej.: 1133221234'}
                                InputProps={{
                                    inputComponent: TextMaskCustom,
                                }}
                            />
                        )}{' '}
                    </Grid>{' '}
                    <Grid item xs={6} sm={6} className=" ">
                        {' '}
                        {showPhoneInput && (
                            <>
                                {' '}
                                <Button
                                    style={styles.container(matches)}
                                    onClick={validateCaptcha}
                                    disabled={!number.phone}
                                    variant="outlined"
                                    color="primary">
                                    ENVIAR PIN{' '}
                                </Button>
                                {getEnv(GOOGLE_RECAPTCHA_KEY) && (
                                    <>
                                        <div className="recaptcha-custom">
                                            <Recaptcha
                                                ref={(ref) => (recaptcha = ref)}
                                                sitekey={getEnv(GOOGLE_RECAPTCHA_KEY)}
                                                onResolved={onResolved}
                                            />{' '}
                                        </div>{' '}
                                    </>
                                )}
                            </>
                        )}{' '}
                    </Grid>{' '}
                </Grid>{' '}
                <Grid item container direction="row">
                    <Grid item xs={12} sm={8} className="mt-1">
                        <i className="fw-400 fst-reg" hidden={showPhoneInput || showManualInputMessage}>
                            Te enviamos un código de 4 dígitos por SMS. Ingresalo a continuación:{' '}
                        </i>{' '}
                        <i className="fw-400 fst-reg" hidden={!showManualInputMessage}>
                            Ocurrió un error al enviar el PIN. Contactate con soporte para poder continuar.{' '}
                        </i>{' '}
                    </Grid>{' '}
                </Grid>{' '}
                <Grid item container direction="row">
                    <Grid item xs={12} className=" ">
                        {' '}
                        {!showPhoneInput && (
                            <>
                                <p className=" fw-400 mt-2">Código de validación {secret}</p>
                                <ReactCodeInput
                                    name="pin"
                                    fields={4}
                                    style={{ marginTop: '20px' }}
                                    onChange={handleChangePin}
                                    value={pin.clientPin}
                                    autoComplete="true"
                                    filterChars="qwertyuiopasdfghjklñzxcvbnm, .-QWERTYUIOPASDFGHJKLÑZXCVBNM{}[]+´|@"
                                    inputMode="numeric"
                                />{' '}
                            </>
                        )}{' '}
                    </Grid>{' '}
                    <Grid item xs={12} sm={12} className="mt-2">
                        {!showPhoneInput && (
                            <Box className="mt-2 mb-2">
                                <i className="fs-12 fw-400" hidden={retryCodeSentSms && !validationCodeSentSms}>
                                    {' '}
                                    (Aguardá {seconds} segundos para reenviar){' '}
                                </i>{' '}
                                {retryCodeSentSms && !validationCodeSentSms && (
                                    <Box className="mt-1 fs-14">
                                        No recibí el SMS,{' '}
                                        <a href="#" className="link fs-14 mt-1 fw-400 color-link-blue no-text-transform" onClick={validateCaptcha}>
                                            enviar de nuevo
                                        </a>
                                    </Box>
                                )}
                                {retryCodeSentSms && !validationCodeSentSms && getEnv(GOOGLE_RECAPTCHA_KEY) && (
                                    <>
                                        <div className="recaptcha-custom">
                                            <Recaptcha
                                                ref={(ref) => (recaptcha = ref)}
                                                sitekey={getEnv(GOOGLE_RECAPTCHA_KEY)}
                                                onResolved={onResolved}
                                            />{' '}
                                        </div>{' '}
                                    </>
                                )}
                            </Box>
                        )}
                    </Grid>{' '}
                </Grid>
            </Grid>{' '}
            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />{' '}
        </Box>
    );
};
