import React, { useState } from 'react';
import { FormControl, FormHelperText, InputLabel, Select } from '@material-ui/core';

export const CustomSelect = ({ children, fullWidth, label, onChange, helperText, errorHelperText, error, inputRef, ...props }) => {
    const handleChange = (...args) => {
        if (onChange) {
            onChange(...args);
        }
    };

    return (
        <FormControl fullWidth={fullWidth} error={error}>
            <InputLabel>{label}</InputLabel>

            <Select onChange={handleChange} error={error} inputRef={inputRef} {...props}>
                {children}
            </Select>
            <FormHelperText>{error ? errorHelperText : helperText}</FormHelperText>
        </FormControl>
    );
};
