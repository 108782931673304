import React, { useState, useEffect } from 'react';
import logo from '../../assets/logo.svg';
import help from '../../assets/help.svg';
import logoColor from '../../assets/logoColor.svg';
import '../../styles/components/navBar.scss';
import { Box, Grid } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import BackButton from '../backButton/BackButton';

import useCurrentTask from '../bpm/useCurrentTask';
import useCreditCardRequest from '../../hooks/useCreditCardRequest';

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: null,
        height: null,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
}

const NavBarSocialMedia = (props) => {
    const { taskDefinitionKey } = props;
    const { hashKey } = props;
    const { keepSameTask } = props;
    const { creditCardRequest } = useCreditCardRequest(hashKey);
    const [cardMigration, setCardMigration] = useState(false);

    const task = useCurrentTask(hashKey, taskDefinitionKey);

    const size = useWindowSize();
    const width = size.width;
    const breakpoint = 600;
    const history = useHistory();
    const urlFrequentQuestions = 'https://www.macro.com.ar/preguntas-frecuentes-mastercard';

    const aditionalColumn = width < 1100 ? <Grid item xs={1}></Grid> : null;

    useEffect(() => {
        setCardMigration('macro-migration-credit-card' === creditCardRequest?.metadata?.Origin);
    }, [creditCardRequest]);

    const goToFAQ = () => {
        if (taskDefinitionKey) {
            history.push({ pathname: `/faq/${hashKey}/${taskDefinitionKey}`, search: history.location.search });
        } else {
            history.push({ pathname: `/faqs`, search: history.location.search });
        }
    };

    const showNavBar =
        width > breakpoint ? (
            <Box className="navbar2 ">
                <Grid container direction="row" justifyContent="center" className="mt-1-5">
                    <Grid item container direction="row" justifyContent="flex-start" xs={6}>
                        <Grid item xs={1}>
                            {task.hasEvents() && (
                                <Box className="navbar4">
                                    <BackButton hashKey={hashKey} taskDefinitionKey={taskDefinitionKey} keepSameTask={keepSameTask}></BackButton>
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={10}>
                            <a href="/">
                                {' '}
                                <img src={logoColor} alt="" />
                            </a>
                        </Grid>
                    </Grid>

                    <Grid item container direction="row" justifyContent="flex-end" xs={6}>
                        {aditionalColumn}
                        <Grid item lg={1} sm={1}></Grid>
                        {aditionalColumn}
                    </Grid>
                </Grid>
            </Box>
        ) : (
            <>
                <Grid container direction="row" item xs={12} justifyContent="flex-end" className="navbarContainer">
                    <Grid item xs={2}>
                        {task.hasEvents() && (
                            <Box className="navbar4">
                                <BackButton hashKey={hashKey} taskDefinitionKey={taskDefinitionKey} keepSameTask={keepSameTask}></BackButton>
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={8}>
                        <Box>
                            <a className="logoNavBar" href="/">
                                {' '}
                                <img src={logo} alt="" />
                            </a>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        {cardMigration && (
                            <Box className="navbar4">
                                <a className="iconMenu" href={urlFrequentQuestions} target="_blank">
                                    {' '}
                                    <img src={help} alt="" />
                                </a>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </>
        );

    return <>{showNavBar}</>;
};

export default NavBarSocialMedia;
