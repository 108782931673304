import React, { useState, useEffect } from 'react';
import UserInput from './UserInput';
import workflowService from './../../services/workflow.service';
import '../../styles/components/userInputs.scss';
import logoexl from '../../assets/exclamacion.svg';
import { Grid, Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const UserInputs = (props) => {
    const { hashKey, stepName, normalizedAddress } = props;
    const [inputGroups, setInputGroups] = useState([]);
    const [userInputFilled, setUserInputFilled] = useState({});
    const [conditioningValues, setConditioningValues] = useState({});

    useEffect(() => {
        async function getUserInputFields() {
            try {
                const userInputs = (await workflowService.getUserInputFields(hashKey, stepName)).data;

                setInputGroups(userInputs.groups);
            } catch (error) {
                console.error(error.response);
            }
        }

        if (hashKey) {
            getUserInputFields();
        }
    }, [hashKey]);

    useEffect(() => {
        const validarForm = async () => {
            props.onComplete(userInputFilled);
        };

        validarForm();
    }, [userInputFilled]);

    const handleUserInputChange = (userInput, value) => {
        setUserInputFilled((previousState) => {
            return { ...previousState, [userInput.name]: value };
        });
        userInput.userInputOptions.inputsThatDependsOnMe.forEach((variableName) => {
            setConditioningValues((previousConditioningValues) => {
                return { ...previousConditioningValues, [variableName]: value };
            });
        });
    };
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const logo = matches ? <img className="logoAdm" src={logoexl} alt="" /> : null;
    return (
        <>
            {inputGroups.map((group, index) => (
                <Grid item container xs={12} className="mt-3 ">
                    <Box key={index} style={{ width: '100%' }}>
                        <Grid container xs={12} direction="row" justify="left" alignItems="left" className="">
                            <Grid item xs={12} className="">
                                <p className="fw-500 fs-16">{group.name}</p>
                            </Grid>

                            
                            <Grid container xs={12} className="mb-1">
                                {group.variables.map((variable) => (
                                    <Grid item xs={12} sm={6}>
                                        <Box key={variable.name}>
                                            <Grid item xs={12} sm={11}>
                                                <Box className="mt-1"></Box>
                                                <UserInput
                                                    hashKey={hashKey}
                                                    userInput={variable}
                                                    stepName={stepName}
                                                    onChange={handleUserInputChange}
                                                    conditioningValue={conditioningValues[variable.name]}
                                                    normalizedAddress={normalizedAddress}
                                                />
                                            </Grid>
                                        </Box>
                                    </Grid>
                                ))}


                                
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            ))}
        </>
    );
};

export default UserInputs;
