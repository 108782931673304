import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Button, Box } from '@material-ui/core';
import deviceCommunicationService from '../../services/deviceCommunication.service';
import '../../styles/components/documentsUpload.scss';

import Loader from '../loader/Loader';
import Qrcode from './qrcode';

import eventTrackingService from '../../services/eventtracking.service';

const MAX_WAIT_TIME = 60;

export const ContinueMobile = (props) => {
    const { hashKey, customerPhone } = props;
    const history = useHistory();
    const [validationCodeSentSms, setValidationCodeSentSms] = useState(false);
    const [seconds, setSeconds] = useState(MAX_WAIT_TIME);
    const [loading, setLoading] = useState(null);

    const handleSendSMSLink = () => {
        setLoading(true);
        eventTrackingService.trackEvent('enviar codigo sms pasaje mobile', null, null, 2);
        setValidationCodeSentSms(true);

        deviceCommunicationService
            .sendPicturesUploadFromMobileTextMessage(hashKey, { urlParams: history.location.search })
            .catch(function (error) {
                console.error(error);
                setLoading(false);
                setValidationCodeSentSms(false);
            });
        setLoading(false);
    };

    useEffect(() => {
        if (validationCodeSentSms) {
            const timer = setInterval(() => {
                setSeconds(seconds - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    });

    useEffect(() => {
        if (seconds <= 0) {
            setValidationCodeSentSms(false);
            setSeconds(MAX_WAIT_TIME);
        }
    }, [seconds]);


    return (
        <>
            <Grid container className="mt-3 ">
                <Grid item container className="mt-4">
                    <Grid item container xs={12} sm={12} direction="column" justifyContent="center" alignItems="center">
                        <h2 className="fw-400 fs-30 text-align-center">Estás solicitando tu tarjeta de crédito <b>Macro</b></h2>
                        <p className="fw-400 fs-24 text-align-center">Te pedimos que continúes desde tu teléfono móvil para poder validar tu identidad</p>
                        <p className="fs-20 fw-400 mt-4">Elegí un método de acceso:</p>
                    </Grid>
                    <Grid item container xs={12} spacing={4} justifyContent="center" alignItems="stretch" style={{ marginTop: '15px' }}>
                        <Grid item container xs={12} sm={4} md={4} justifyContent="center" alignItems="center">
                            <Box boxShadow={7} className="box-qr-option">
                                <h3 className="color-secondary">Escaneá este QR</h3>
                                <div className="center" style={{ paddingTop: 20 }}>
                                    <Qrcode className="center " hashKey={hashKey} />
                                </div>
                            </Box>
                        </Grid>
                        <Grid item container xs={12} sm={4} md={4} justifyContent="center" alignItems="stretch">
                            <Loader loading={loading} />
                            <Box boxShadow={4} className="box-qr-option">
                                <h3 className="color-secondary mb-3">Envío de SMS</h3>
                                <p className="mb-3">Podemos enviarte un SMS con el link para que continúes desde tu teléfono móvil</p>
                                <Button
                                    style={{ width: '80%', height: '40px' }}
                                    onClick={handleSendSMSLink}
                                    disabled={validationCodeSentSms}
                                    variant="outlined"
                                    color="primary">
                                    Enviar SMS
                                </Button>

                                <Box>
                                    <i className="mt-1 fs-12 fw-400 fst-reg" hidden={!validationCodeSentSms}>
                                        Te enviamos un código por SMS
                                    </i>
                                </Box>
                                <Box>
                                    <i className="fs-12 fw-400 fst-reg" hidden={!validationCodeSentSms}>
                                        {' '}
                                        (Aguardá {seconds} segundos para reenviar)
                                    </i>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
export default ContinueMobile;
