import React, { useEffect, useState } from 'react';
import { Grid, FormGroup, FormControlLabel, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import termService from '../../services/term.service';

const AcceptCardCancellation = (props) => {
    const [accepted, setAccepted] = useState(false);
    const [terms, setTerms] = useState(null);

    useEffect(() => {
        getTerms();
    }, []);

    const getTerms = async () => {
        try {
            const terms = (await termService.getTerms('macro-credit-card')).data;
            setTerms(terms);
        } catch (error) {
            console.log(error);
        }
    };

    const handleAcceptChange = (event) => {
        setAccepted(event.target.checked);
        props.onChange(event.target.checked, terms.id);
    };

    const label = (
        <Typography style={{ color: '#212121', fontSize: '14px', fontFamily: 'Roboto' }}>
            Acepto la baja de mi tarjeta de crédito Mastercard Macro
        </Typography>
    );

    if (!terms) {
        return null;
    }
    return (
        <Grid container justifyContent="center">
            <Grid style={{ margin: '5px 32px 0px 32px' }}>
                <FormGroup row>
                    <FormControlLabel
                        control={<Checkbox checked={accepted} onChange={handleAcceptChange} name="accepted" color="primary" />}
                        label={label}
                    />
                </FormGroup>
            </Grid>
        </Grid>
    );
};

export default AcceptCardCancellation;
