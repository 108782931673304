import React, { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { UNDEFINED_VALUE } from './userInputConstants';
import Map from '../googleMap/Map';
import workflowService from '../../services/workflow.service';
import { Grid, Box, MenuItem, Button, ButtonGroup } from '@material-ui/core';
import '../../styles/components/userInputs.scss';
import { CustomSelect } from '../customInputs/CustomSelect';
import { GOOGLE_MAP_API_KEY, getEnv } from '../../env';

const MapUserInput = (props) => {
    const { conditioningValue, hashKey, stepName, userInput, onChange, normalizedAddress } = props;
    const [markerList, setMarkerList] = useState(userInput.userInputOptions.comboData);
    const [value, setMapValue] = useState(UNDEFINED_VALUE);
    const mapURL = 'https://maps.googleapis.com/maps/api/js?v=3.exp&key=' + getEnv(GOOGLE_MAP_API_KEY);
    const [showMap, setShowMap] = useState(true);
    const [description, setDescription] = useState(null);
    const [deliveryEnabled, setDeliveryEnabled] = useState(false);
    const { control, setValue } = useFormContext();

    useEffect(() => {
        if (value) {
            onChange(userInput, value);
        }
    }, [value, markerList]);

    useEffect(() => {
        async function getComboData() {
            try {
                let comboData = (await workflowService.getUserInputComboData(hashKey, userInput.name, stepName, conditioningValue)).data;
                setMarkerList(comboData);
            } catch (error) {
                // do nothing
            }
        }
        if (conditioningValue) {
            getComboData();
        }
    }, [conditioningValue]);

    const handleMarkerChange = (userInput, selectedMarker) => {
        if (selectedMarker !== null) {
            setValue(userInput.name, selectedMarker.id);
            onChange(userInput, selectedMarker.id);
        } else {
            setValue(userInput.name, null);
            onChange(userInput, null);
        }

        if (selectedMarker && selectedMarker !== null) {
            setMapValue(selectedMarker.id);
            setDescription(selectedMarker.descripcion);
            setDeliveryEnabled(selectedMarker.aditionalData.deliveryEnabled);
        }
    };

    const handleComboChange = (event) => {
        setMapValue(event.target.value);
    };

    const handleShowMap = () => {
        setMapValue(null);
        setShowMap(true);
    };

    const handleShowList = () => {
        setMapValue(null);
        setShowMap(false);
    };

    const getVariantMap = () => {
        return showMap ? 'contained' : 'outlined';
    };

    const getVariantList = () => {
        return !showMap ? 'contained' : 'outlined';
    };

    return (
        <Grid container className="mt-1">
            <Grid item xs={12} sm={12}>
                <p className="fw-400 color-dark-gray">{userInput.userInputOptions.label}</p>
            </Grid>
            <Grid item xs={12} sm={12}>
                <div className="mt-1">
                    <ButtonGroup disableElevation color="secondary" fullWidth size="large">
                        <Button onClick={handleShowMap} variant={getVariantMap()}>
                            Mapa
                        </Button>
                        <Button onClick={handleShowList} variant={getVariantList()}>
                            Listado
                        </Button>
                    </ButtonGroup>
                </div>
                <Box className="mt-1"></Box>
                <Controller
                    name={userInput.name}
                    control={control}
                    defaultValue={userInput.userInputOptions.defaultValue}
                    rules={{ required: userInput.userInputOptions.required }}
                    render={({ field: { ref, onChange, value }, fieldState: { error } }) => {
                        return showMap ? (
                            <>
                                <Grid item xs={12}>
                                    <Box className="mt-2"> </Box>
                                    <div className="selecteddiv flex center ">
                                        <Grid container direction="row" justify="center" alignItems="center">
                                            <Grid item lg={12} sm={12} md={12} xl={12} xs={12}>
                                                {value ? (
                                                    <>
                                                        <p class="fw-700 color-dark-gray">Sucursal seleccionada</p>
                                                        <p class="fw-500 color-dark-gray">{description}</p>
                                                        {!deliveryEnabled && normalizedAddress && (
                                                            <p class="fw-500 color-dark-gray">
                                                                <i>
                                                                    Esta sucursal no cuenta con envío a domicilio por lo que deberás retirar la
                                                                    tarjeta personalmente.
                                                                </i>
                                                            </p>
                                                        )}
                                                    </>
                                                ) : (
                                                    <p class="fw-600 color-dark-gray">Seleccioná una sucursal en el mapa para poder continuar</p>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Map
                                    value={value}
                                    googleMapURL={mapURL}
                                    onChange={handleMarkerChange}
                                    markerList={markerList}
                                    userInput={userInput}
                                    containerElement={<div style={{ height: '300px', width: 'auto', marginBottom: '40px', marginTop: '10px' }} />}
                                    mapElement={<div style={{ height: '300px', width: 'auto', borderRadius: '5px' }} />}
                                    loadingElement={<p>Cargando...</p>}
                                />
                            </>
                        ) : (
                            <CustomSelect
                                inputRef={ref}
                                error={!!error}
                                fullWidth={true}
                                required={userInput.userInputOptions.required}
                                name={userInput.name}
                                label={userInput.userInputOptions.label}
                                onChange={(e) => {
                                    onChange(e);
                                    handleComboChange(e);
                                }}
                                value={value}
                                disabled={false}
                                errorHelperText="Requerido">
                                {markerList.map((item) => (
                                    <MenuItem id={item.id} key={item.id} value={item.id}>
                                        {item.descripcion}
                                    </MenuItem>
                                ))}
                                {markerList.length > 1 && <MenuItem value={UNDEFINED_VALUE}>Seleccione alguna de las opciones</MenuItem>}
                            </CustomSelect>
                        );
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default MapUserInput;
