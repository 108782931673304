export const ANALYTICS_EVENT = {};

export const ERROR = {
    NO_ACTIVE_TASKS: 'NO_ACTIVE_TASKS',
    UNKNOWN: 'UNKNOWN',
};

export const CREDIT_CARD_REQUEST_STATUS = {
    EXPIRED: 'EXPIRED',
    FINALIZED: 'FINALIZED',
    READY_FOR_DELIVERY: 'READY_FOR_DELIVERY',
    REJECTED: 'REJECTED',
    UNDER_REVIEW: 'UNDER_REVIEW',
};

export const LOCAL_STORAGE = {};

export const ROUTE = {
    ERROR_NON_RETRYABLE: '/noRetryable',
    ERROR_SESSION_EXPIRED: '/sessionExpired',
    REJECTED: '/rejected',
    START: '/',
    SUCCESS: '/success',
};
