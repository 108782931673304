import React from 'react';
import { Grid, Box, useMediaQuery, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import '../styles/components/error.scss';
import '../styles/components/sessionExpired.scss';
import MacroPage from './shared/MacroPage';
import MainButton from '../components/MainButton';

export const SessionExpired = (props) => {
    const { history } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const handleReconnect = (event) => {
        event.preventDefault();
        history.push({ pathname: `/`, search: history.location.search });
    };

    return (
        <>
            <MacroPage>
                <Grid container direction="column" className="">
                    <Box className="assetBgMessagePages"></Box>
                    <Grid item xs={12}>
                        <Box className="pushDiv"></Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container direction="row" alignItems="center" justifyContent="center" className="sessionContainer">
                            <Grid item container xs={10} direction="row" alignItems="center" justifyContent="center">
                                <Grid item container xs={12} md={8} justifyContent="center">
                                    <Grid item xs={12}>
                                        <p className="fs-28 fw-600 ls-33 text-align-center color-dark-blue">{t('SESSION_EXPIRED.TITLE')}</p>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box className="mt-3"> </Box>
                                        <p className="fs-22 fw-400 text-align-center color-dark-blue">{t('SESSION_EXPIRED.DESCRIPTION')}</p>
                                    </Grid>

                                    {!smDown && (
                                        <Grid item xs={12} sm={6}>
                                            <MainButton onClick={handleReconnect} style={{ width: '100%' }}>{t('SESSION_EXPIRED.BUTTON')}</MainButton>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {smDown && (
                    <Grid item xs={12}>
                        <div className="startButton center fixed-bottom-padding">
                            <Button className="flex center width-80-per" variant="contained" color="primary" onClick={handleReconnect}>
                                {t('SESSION_EXPIRED.BUTTON')}
                            </Button>
                        </div>
                    </Grid>
                )}
            </MacroPage>
        </>
    );
};
