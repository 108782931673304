import React, { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { UNDEFINED_VALUE } from './userInputConstants';
import { CustomTextField } from '../customInputs/CustomTextField';

const TextUserInput = (props) => {
    const { userInput, onChange } = props;
    const [value, setValue] = useState(UNDEFINED_VALUE);
    const [regex, setRegex] = useState(null);
    const { control } = useFormContext();

    useEffect(() => {
        if (value) {
            onChange(userInput, value);
        }
    }, [value]);

    useEffect(() => {
        if (userInput) {
            if (userInput.userInputOptions.pattern) {
                setRegex(new RegExp(userInput.userInputOptions.pattern));
            }
            if (userInput.userInputOptions.defaultValue) {
                setValue(userInput.userInputOptions.defaultValue);
            }
        }
    }, [userInput]);

    const handleTextFieldChange = (event) => {
        const newValue = event.target.value;
        if (!regex || newValue.match(regex)) {
            setValue(newValue);
        }
    };

    return (
        <Controller
            name={userInput.name}
            control={control}
            defaultValue={userInput.userInputOptions.defaultValue}
            rules={{ required: userInput.userInputOptions.required }}
            render={({ field: { ref, onChange, value }, fieldState: { error } }) => (
                <CustomTextField
                    disabled={userInput.userInputOptions.disabled}
                    fullWidth={true}
                    inputRef={ref}
                    value={value}
                    name={userInput.name}
                    label={userInput.userInputOptions.label}
                    error={!!error}
                    onChange={(e) => {
                        onChange(e);
                        handleTextFieldChange(e);
                    }}
                    errorHelperText="Requerido"
                    helperText={userInput.userInputOptions.hint}
                />
            )}
        />
    );
};

export default TextUserInput;
