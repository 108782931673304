import axiosInstance from './axios.config';

const RESTAPI_VERSION = '/v1';

const normalizationService = {
    normalizeAddress: (address) => {
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/creditcard/address-normalizations`, { params: address });
    }
};

export default normalizationService;