import React from 'react';
import { Grid, useTheme, useMediaQuery, Box, makeStyles } from '@material-ui/core';
import NavBarSocialMedia from '../../components/navBar/NavBarSocialMedia';
import bgImg from '../../assets/bg-success.svg';
import check from '../../assets/check.svg';

const LoanRequestResultPage = (props) => {
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'));
    
    const useStyles = makeStyles(() => ({
        successBox: {
            backgroundColor: '#EFEFEF',
            backgroundImage: `url(${bgImg})`,
            backgroundPosition: 'top left',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        },
        checkCreditCard: {
            width: '75px',
            position: 'absolute',
            right: '-15px',
            bottom: '-15px',
        },
        positionRelative: {
            position: 'relative',
        },
    }));
    const classes = useStyles();

    return (
        <>
            <NavBarSocialMedia></NavBarSocialMedia>
            <Grid item container justifyContent="center" className={classes.successBox}>
                <Grid item container xs={10} justifyContent="center">
                    <Grid item container xs={12} justifyContent="center">
                        <Box m={7} />
                    </Grid>
                    <Grid item container xs={12} sm={6} md={6} justifyContent="center">
                        <Grid item container xs={8}  className={classes.positionRelative}>
                            {props.resultImage}
                            <img src={check} alt="" className={classes.checkCreditCard} />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} justifyContent="center">
                        <Box m={2} />
                    </Grid>
                    <Grid item xs={12}>
                        <p style={{ color: '#203AE9' }} className={`text-align-center fs-${smUp ? '38 fw-500' : '22 fw-700'}`}>{props.headerTitle}</p>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <p className={`text-align-center fs-${smUp ? '38 fw-500' : '22 fw-700'}`}>Hemos recibido tu solicitud</p>
                    </Grid>

                    <Grid item container xs={12} sm={10} md={6} className="">
                        <Grid item xs={12}>
                        
                            {props.cardMigration ?
                                <>
                                <Grid item container className="mt-4">
                                <p className={`text-align-center fw-400 fs-${smUp ? '24' : '18'}`}>
                                    La gestión por la tarjeta 
                                    {' '}
                                    <b>{props.cardType}</b>
                                    {' '}
                                    está en proceso.
                                </p>
                                </Grid>
                                <Grid item container className="mt-4 mb-3">
                                <p className={`text-align-center fw-400 fs-${smUp ? '24' : '18'}`}>
                                    En instantes recibirás un email con los datos de esta gestión y los detalles de tus productos.
                                </p>
                                </Grid>
                                </>
                            :
                            <>
                            <Grid item container className="mt-4">
                                <p className={`text-align-center fw-400 fs-${smUp ? '24' : '18'}`}>
                                    La gestión por el Servicio 
                                    {' '}
                                    <b>{props.title}</b>
                                    {' '}
                                    con tu <b>tarjeta</b>
                                    {' '}
                                    <b>{props.cardType}</b>
                                    {' '}
                                    está en proceso.
                                </p>
                                </Grid>
                                <Grid item container className="mt-4 mb-3">
                                <p className={`text-align-center fw-400 fs-${smUp ? '24' : '18'}`}>
                                    Te enviamos un mail con los datos de la gestión y el detalle de tus productos.
                                </p>
                                </Grid>
                                </>
                            }
                        
                            
                        </Grid>

                        {props.children}
                    </Grid>
                    <Grid item container xs={12} justifyContent="center">
                        <Box m={2} />
                    </Grid>
                </Grid>
            </Grid>
            <div className="mt-4-landscape"></div>
        </>
    );
};

export default LoanRequestResultPage;
