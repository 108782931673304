import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './styles/styles.scss';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Theme from './styles/resources/themes/theme';
import App from './App';
import './i18n';

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback="loading">
            <MuiThemeProvider theme={Theme}>
                <App></App>
            </MuiThemeProvider>
        </Suspense>
    </React.StrictMode>,

    document.getElementById('root')
);
