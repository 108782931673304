import React, { useState, useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { useFormContext, Controller } from 'react-hook-form';
import workflowService from '../../services/workflow.service';
import { UNDEFINED_VALUE } from './userInputConstants';
import { CustomSelect } from '../customInputs/CustomSelect';

const ComboUserInput = (props) => {
    const { conditioningValue, hashKey, stepName, userInput, onChange, defaultDisabled } = props;
    const [comboList, setComboList] = useState(userInput.userInputOptions.comboData);
    const [defaultValue, setDefaultValue] = useState(null);
    const [value, setValue] = useState(UNDEFINED_VALUE);
    const { control } = useFormContext();

    useEffect(() => {
        if (!userInput) {
            return;
        }
        if (userInput.userInputOptions.defaultValue !== defaultValue) {
            setDefaultValue(userInput.userInputOptions.defaultValue);
        }
    }, [userInput]);

    useEffect(() => {
        if (!defaultValue) {
            return;
        }
        if (comboList) {
            comboList.forEach((item) => {
                if (item.id === defaultValue) {
                    setValue(item.id);
                    setDefaultValue(null);
                }
            });
        }
    }, [defaultValue]);

    useEffect(() => {
        setValue(UNDEFINED_VALUE);

        async function getComboData() {
            try {
                let comboData = (await workflowService.getUserInputComboData(hashKey, userInput.name, stepName, conditioningValue)).data;
                setComboList(comboData);
            } catch (error) {
                //
            }
        }
        if (conditioningValue) {
            getComboData();
        }
    }, [conditioningValue]);

    useEffect(() => {
        if (value && value !== UNDEFINED_VALUE) {
            onChange(userInput, value);
        }
    }, [value]);

    useEffect(() => {
        if (comboList) {
            comboList.forEach((item) => {
                if (item.selected || item.id == defaultValue) {
                    setValue(item.id);
                    setDefaultValue(null);
                }
            });
        }
    }, [comboList]);

    const handleComboChange = (event) => {
        setValue(event.target.value);
        setDefaultValue(null);
    };

    const getDefaultValue = () => {
        const defaultValue = userInput?.userInputOptions.comboData?.find((item) => item.selected)?.id || userInput?.userInputOptions.defaultValue;
        return defaultValue;
    };

    return (
        <Controller
            name={userInput.name}
            control={control}
            defaultValue={getDefaultValue()}
            rules={{ required: userInput.userInputOptions.required }}
            render={({ field: { ref, onChange, value }, fieldState: { error } }) =>
                comboList && (
                    <CustomSelect
                        inputRef={ref}
                        error={!!error}
                        fullWidth={true}
                        label={userInput.userInputOptions.label}
                        onChange={(e) => {
                            onChange(e);
                            handleComboChange(e);
                        }}
                        value={value}
                        disabled={comboList.length <= 1 || defaultDisabled}
                        errorHelperText="Requerido">
                        {comboList.map((item) => (
                            <MenuItem id={item.id} key={item.id} value={item.id}>
                                {item.descripcion}
                            </MenuItem>
                        ))}
                        {comboList.length > 1 && <MenuItem value={UNDEFINED_VALUE}>Seleccione alguna de las opciones</MenuItem>}
                    </CustomSelect>
                )
            }
        />
    );
};

export default ComboUserInput;
