import React from 'react';
import { TextField } from '@material-ui/core';

export const CustomTextField = ({ onChange, helperText, errorHelperText, error, inputRef, ...props }) => {
    const handleChange = (...args) => {
        if (onChange) {
            onChange(...args);
        }
    };

    return <TextField onChange={handleChange} inputRef={inputRef} error={error} helperText={error ? errorHelperText : helperText} {...props} />;
};
