import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, withScriptjs, withGoogleMap, InfoWindow } from 'react-google-maps';
import { UNDEFINED_VALUE } from '../userInputs/userInputConstants';

const Map = ({ userInput, onChange, markerList, value }) => {
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [defaultLat, setDefaultLat] = useState(UNDEFINED_VALUE);
    const [defaultLng, setDefaultLng] = useState(UNDEFINED_VALUE);

    useEffect(() => {
        if (!markerList?.length) {
            return;
        }
        const marker = markerList.find((marker) => marker.id === value) || markerList[0];
        setDefaultLat(marker.aditionalData.lat);
        setDefaultLng(marker.aditionalData.lng);
    }, [markerList, value]);

    useEffect(() => {
        if (!markerList?.length) {
            return;
        }
        if (!value) {
            return;
        }
        const marker = markerList.find((marker) => marker.id === value) || markerList[0];
        setSelectedMarker(marker);
    }, [markerList, value]);

    useEffect(() => {
        if (selectedMarker && selectedMarker !== UNDEFINED_VALUE) {
            onChange(userInput, selectedMarker);
        } else {
            onChange(userInput, null);
        }
    }, [selectedMarker]);

    return (
        <GoogleMap defaultZoom={10} center={{ lat: defaultLat ? defaultLat : 0, lng: defaultLng ? defaultLng : 0 }}>
            {markerList.map((marker, i) => {
                return (
                    <Marker
                        key={i}
                        position={{ lat: marker.aditionalData.lat, lng: marker.aditionalData.lng }}
                        text="My Marker"
                        onClick={() => {
                            setSelectedMarker(marker);
                        }}>
                        {selectedMarker && selectedMarker.id === marker.id && (
                            <InfoWindow
                                onCloseClick={() => {
                                    setSelectedMarker(null);
                                }}>
                                <span>{marker.descripcion}</span>
                            </InfoWindow>
                        )}
                    </Marker>
                );
            })}
        </GoogleMap>
    );
};

export default withScriptjs(withGoogleMap(Map));
