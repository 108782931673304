import axiosInstance from './axios.config';
import axiosLocalInstance from './axios.config.local';

const RESTAPI_VERSION = '/v1';

const termService = {
    getTerms: (code) => {
        return axiosInstance.get(`${RESTAPI_VERSION}/user/terms/${code}`);
    },
    acceptTerms: (userCode, termsId) => {
        const payload = {
            userCode: userCode,
            termsId: termsId,
        };
        return axiosInstance.post(`${RESTAPI_VERSION}/user/terms/accept`, payload);
    },
    getTermsHtml: (termsFileName) => {
        return axiosLocalInstance.get(`/terms/${termsFileName}`);
    },
};

export default termService;
