import React, { useRef, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import useCurrentTask from '../components/bpm/useCurrentTask';
import './../styles/components/customerDataInput.scss';
import MacroStepPage from './shared/MacroStepPage';
import Toaster from '../components/alerts/Toaster';
import eventTrackingService from '../services/eventtracking.service';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { useForm, FormProvider } from 'react-hook-form';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import CustomerDataUserInputs from '../components/userInputs/CustomerDataUserInputs';
import useCreditCardRequest from '../hooks/useCreditCardRequest';
import creditCardRequestService from '../services/creditCardRequest.service';
import Loader from '../components/loader/Loader';

export const PersonalAddressDataInput = (props) => {
    const { hashKey } = props.match.params;
    const { taskDefinitionKey } = props;
    const [userInputs, setUserInputs] = useState({});
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const { creditCardRequest } = useCreditCardRequest(hashKey);
    const task = useCurrentTask(hashKey, taskDefinitionKey);
    const { t } = useTranslation();
    const formRef = useRef();
    const [loading, setLoading] = useState(false);
    const { handleSubmit, ...methods } = useForm({ shouldFocusError: true });

    const completeTask = async (userInputs) => {
        try {
            setLoading(true);
            await creditCardRequestService.setCustomerPersonalAddressData(hashKey, userInputs);
            await task.completeTask();
        } catch (error) {
            let errorMessage = t('WORKFLOW_ERROR.UNKNOWN_ERROR.DESCRIPTION');
            if (error.response && error.response.data) {
                const { properties, errorCode } = error.response.data;
                if (errorCode) {
                    errorMessage = t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`);

                    if (errorCode === 'ALL_FIELDS_REQUIERED_MUST_BE_COMPLETED' && properties.fieldsToCompleted) {
                        const { fieldsToCompleted } = properties;
                        fieldsToCompleted.map((field) => (errorMessage += ' ' + t(`FIELDS.${field}`)));
                    }
                }
            }
            setMessage(errorMessage);
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleNextPressed = async (data) => {
        setLoading(true);
        try {
            await formRef.current.handleFormSubmission();
        } catch (error) {
            // do nothing with the error
        } finally {
            setLoading(false);
        }
    };

    const handleUserInputsLoaded = () => {
        setLoading(false);
    };

    const handleComplete = (userInputFilled) => {
        setUserInputs(userInputFilled);
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const handleFormError = (error) => {
        setMessage(error.message);
        setOpen(true);
    };

    const handleOnAnalyticsEvent = (inputName, eventType, value) => {
        if(inputName === 'PtllaLocalidad' && eventType === "EMPTY_OPTIONS_LIST") {
            eventTrackingService.trackEvent('localidad_erronea', value, null);
        }
    }

    return (
        <>
            <Loader loading={loading} />
            <MacroStepPage
                headerLead="Tu tarjeta de crédito está casi lista"
                headerTitle="Completá tus datos personales"
                hashKey={hashKey}
                task={task}
                taskDefinitionKey={taskDefinitionKey}
                backToText={isMobile ? 'Selección de Oferta' : undefined}
                hideStepper={true}
                stepNumber={3}
                totalSteps={4}>
                {!isMobile ? (
                    <ContinueMobile hashKey={hashKey} />
                ) : (
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(handleNextPressed)}>
                            <Grid item container className="mb-100-res">
                                {creditCardRequest && (
                                    <CustomerDataUserInputs
                                        ref={formRef}
                                        sameLineIndexes={[1, 2, 3]}
                                        stepName={taskDefinitionKey}
                                        hashKey={creditCardRequest.hashKey}
                                        onComplete={handleComplete}
                                        onLoaded={handleUserInputsLoaded}
                                        onFormSubmitted={completeTask}
                                        onError={handleFormError}
                                        onAnalyticsEvent={handleOnAnalyticsEvent}
                                    />
                                )}
                            </Grid>

                            <Grid item container direction="row" justify="center" alignItems="center" xs={12}>
                                <div className="mb-footer validationIdentityButton center mt-20-web">
                                    <Button
                                        className="width-100-per width-90-res"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={loading}>
                                        Siguiente
                                    </Button>
                                </div>
                            </Grid>
                            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                        </form>
                    </FormProvider>
                )}
            </MacroStepPage>
        </>
    );
};
