import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: theme.palette.primary.main,
        fontFamily: 'Titillium Web, sans-serif',
        fontWeight: 'bolder',
        fontSize: '16px',
        width: '300px',
        marginTop: '40px',
        marginBottom: '40px',
    },
    fixed: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            marginBottom: 0,
        },
    },
}));

function MainButton({ fixedBottomMobile, ...props }) {
    const classes = useStyles();
    const fixedBottomMobileClass = fixedBottomMobile ? classes.fixed : '';

    return (
        <Button {...props} variant="contained" color="primary" className={`${props.className} ${classes.button} ${fixedBottomMobileClass}`}>
            {props.children}
        </Button>
    );
}

export default MainButton;
