import { Box, Button, Grid, Hidden } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { ApiContext } from '../useLogin';
import { TextField } from '@material-ui/core';

export const CheckEmail = (props) => {
    const { onComplete, onEmailChanged } = props;
    const { email, setEmail } = useContext(ApiContext);
    const [showEmailInput, setShowEmailInput] = useState(props.showEmailInput);
    const mediaMatch = window.matchMedia('(min-width: 500px)');
    const [matches, setMatches] = useState(mediaMatch.matches);

    const styles = {
        container: (isFullWidth) => ({
            marginTop: isFullWidth ? '20px' : '0',
            minWidth: isFullWidth ? '90%' : '100%',
            marginLeft: isFullWidth ? '10px' : '0px',
            maxWidth: '100%',
            width: isFullWidth ? '90%' : '100%',
        }),
    };

    const emailRegex = /\S+@\S+\.\S+/;

    const isEmail = (email) => {
        return emailRegex.test(email);
    };

    const userInput = {
        name: 'Email',
        userInputOptions: {
            disabled: false,
            required: true,
            label: 'Email',
            hint: 'Ej: ejemplo@gmail.com',
        },
    };

    const [isValid, setIsValid] = useState(false);
    const [mailModified, setMailModified] = useState(false);

    const handleEmailChanged = (event) => {
        setMailModified(true);
        if (isEmail(event.target.value)) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }

        setEmail({
            ...setEmail,
            clientEmail: event.target.value.trim(),
        });
    };

    const handleChangeEmailClicked = () => {
        setIsValid(false);
        setMailModified(false);
        setShowEmailInput(true);
        setEmail({
            ...setEmail,
            clientEmail: null,
        });
        onEmailChanged();
    };

    const handleNextClicked = () => {
        setMailModified(true);
        if (isValid) {
            setShowEmailInput(false);
            onComplete();
        }
    };

    return (
        <Box className="">
            {showEmailInput && (
                <>
                    <Grid item xs={12} sm={12} style={{ minHeight: '185px' }}>
                        {' '}
                        <p className=" fw-400 mt-1 mb-1"> Ingresá tu email </p>{' '}
                        <TextField
                            onChange={handleEmailChanged}
                            error={!isValid && mailModified}
                            disabled={userInput.userInputOptions.disabled}
                            fullWidth={true}
                            required={userInput.userInputOptions.required}
                            name={userInput.name}
                            label={userInput.userInputOptions.label}
                            errorHelperText="Requerido. Cargá un email válido"
                            helperText={userInput.userInputOptions.hint}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} className="fixed-bottom-button button-container">
                        <Button style={styles.container(matches)} onClick={handleNextClicked} variant="contained" color="primary">
                            Siguiente{' '}
                        </Button>
                        <Hidden mdUp>
                            <Box m={3} style={{ marginBottom: '60px' }} />
                        </Hidden>
                    </Grid>
                </>
            )}{' '}
            {!showEmailInput && (
                <>
                    <Box className="mb-1">
                        <strong className="fs-14 fw-600"> Email {email.clientEmail} - </strong>{' '}
                        <a href="#" className="link fs-14 fw-400 color-link-blue" onClick={handleChangeEmailClicked}>
                            Editar{' '}
                        </a>{' '}
                    </Box>
                </>
            )}
        </Box>
    );
};
