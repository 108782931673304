import axiosInstance from './axios.config';
import eventTrackingService from './eventtracking.service';

const RESTAPI_VERSION = '/v1';

const deviceCommunicationService = {
    getDocumentsUploadMiniUrl: (hashKey, requestDto) => {
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/creditcard/${hashKey}/documentUpload/minihash`, requestDto);
    },

    getHashkeyFromMinihash: (minHash) => {
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/creditcard/minhash/${minHash}`).then((response) => {
            eventTrackingService.setIsClient(response.data.customerData.CobisId !== null ? '1' : '0');
            eventTrackingService.setCustomerId(response.data.customerIdNumber);
            eventTrackingService.setCustomerEmail(response.data.customerData?.CustomerMail);
            return response;
        });
    },

    getMinifiedUrl: (minHash) => {
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/creditcard/minified-urls/${minHash}`);
    },

    getMinifiedHash: (hashId) => {
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/onscore/minified-urls/${hashId}`);
    },

    sendPicturesUploadFromMobileTextMessage: (hashKey, requestDto) => {
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/creditcard/${hashKey}/documents-upload-links`, requestDto);
    },
};

export default deviceCommunicationService;
