import React, { useEffect, useState } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import termService from '../../services/term.service';

const AcceptMigrationContractCheckbox = (props) => {
    const [accepted, setAccepted] = useState(false);
    const [terms, setTerms] = useState(null);
    const [termsVisible, setTermsVisible] = useState(false);

    useEffect(() => {
        getTerms();
    }, []);

    const getTerms = async () => {
        try {
            const terms = (await termService.getTerms('macro-credit-card')).data;
            setTerms(terms);
        } catch (error) {
            console.log(error);
        }
    };

    const handleAcceptChange = (event) => {
        setAccepted(event.target.checked);
        props.onChange(event.target.checked, terms.id);
    };

    const handleTermsClick = (event) => {
        props.onContractLinkClicked(event);
    };

    const label = (
        <span>
            Acepto los{' '}
            <a href="#" onClick={handleTermsClick}>
                Términos y Condiciones
            </a>{' '}
            del Paquete de Productos y suscribo al Contrato de Tarjeta de Crédito
        </span>
    );

    if (!terms) {
        return null;
    }
    return (
        <div className="flex center mt-3">
            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={accepted} onChange={handleAcceptChange} name="accepted" color="primary" />}
                    label={label}
                />
            </FormGroup>
        </div>
    );
};

export default AcceptMigrationContractCheckbox;
