import axiosInstance from './axios.config';
import eventTrackingService from './eventtracking.service';

const RESTAPI_VERSION = '/v1';

export const creditCardRequestService = {
    createMigrationCreditCardRequest: (cobisId) => {
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/creditcard/start`, {
            workflowName: 'macro-migration-credit-card',
            idCommerce: 4,
            idProduct: 999999,
            idSubProduct: 999999,
            preCustomerData: {
                CobisId: cobisId,
            },
            
        });
    },
    createRequest: (cuit, dni, customerName, firstName, lastName, sex, birthday, email, phone, pin, _marketingData, _promoterInfo) => {
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/creditcard/start`, {
            workflowName: 'macro-credit-card',
            idCommerce: 4,
            idProduct: 999999,
            idSubProduct: 999999,
            birthDate: new Date(birthday),
            clientEmail: email,
            names: firstName,
            surNames: lastName,
            customerName: customerName,
            preCustomerData: {
                PtllaCUIT: cuit,
                PtllaDNI: dni,
                PtllaDNIUsedValue: dni,
                CustomerSex: sex,
                CustomerCellphone: phone,
                CustomerCellphoneVerificationCode: pin,
                CustomerBirthdate: new Date(birthday),
                CustomerMail: email,
            },
            marketingData: _marketingData,
            promoterInfo: _promoterInfo,
        });
    },
    getDecryptedCustomerData: function (encryptedCustomerData) {
        const params = {
            encryptedCustomerData: encryptedCustomerData,
        };
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/query-params`, { params: params });
    },
    getCreditCardRequest: (hashKey) => {
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/creditcard/${hashKey}`).then((response) => {
            eventTrackingService.setIsClient(response.data.customerData.CobisId !== null ? '1' : '0');
            eventTrackingService.setCustomerId(response.data.customerIdNumber);
            eventTrackingService.setCustomerEmail(response.data.customerData?.CustomerMail);
            return response;
        });
    },
    finishRequestWithoutSign: (creditCardRequestId) => {
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/creditcard/${creditCardRequestId}/finishRequestWithoutSign`);
    },
    setCustomerAfipData: (hashKey, birthDate) => {
        const dto = {
            birthDate: birthDate,
        };
        return axiosInstance.put(`${RESTAPI_VERSION}/workflow/creditcard/${hashKey}/customerAfipData`, dto);
    },
    setProvinceCode: (hashKey, provinceCode) => {
        const loanRequestDto = {
            branchProvinceCode: provinceCode,
        };
        return axiosInstance.put(`${RESTAPI_VERSION}/workflow/loanRequest/${hashKey}/province`, loanRequestDto);
    },
    setCustomerPersonalAddressData: (hashKey, customerData) => {
        const dto = {
            hashkey: hashKey,
            customerDataMap: customerData,
        };
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/creditcard/${hashKey}/customerpersonaladdressdata`, dto);
    },
    setCustomerAffidavitData: (hashKey, customerData) => {
        const dto = {
            hashkey: hashKey,
            customerDataMap: customerData,
        };
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/creditcard/${hashKey}/customeraffidavitdata`, dto);
    },
    setCustomerDeliveryInfo: (hashKey, selectedDeliveryAddress, isBranchAddressSelected) => {
        const dto = {
            deliveryAddress: selectedDeliveryAddress,
            branchAddress: isBranchAddressSelected,
        };
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/creditcard/${hashKey}/deliveryinfo`, dto);
    },
    hasAnyAccount: (hashKey) => {
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/creditcard/${hashKey}/hasAnyAccount`);
    },
    setOfficeDataForAccountOpening: (hashKey, customerData) => {
        const dto = {
            hashkey: hashKey,
            customerDataMap: customerData,
        };
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/creditcard/${hashKey}/officeDataForAccountOpening`, dto);
    },
};

export default creditCardRequestService;
