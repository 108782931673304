import React, { useEffect, useState, Suspense } from 'react';
import MacroPage from './shared/MacroPage';
import { Button, Grid, Box } from '@material-ui/core';
import useCurrentTask from '../components/bpm/useCurrentTask';
import './../styles/components/provinceSelection.scss';
import DateUserInput from '../components/userInputs/DateUserInput';
import Loading from '../components/loader/Loader';
import Loader from '../components/loader/Loader';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Toaster from '../components/alerts/Toaster';
import useCreditCardRequest from '../hooks/useCreditCardRequest';
import creditCardRequestService from '../services/creditCardRequest.service';

export const CustomerAfipData = (props) => {
    const { hashKey } = props.match.params;
    const { taskDefinitionKey } = props;
    const [validForm, setValidForm] = useState(false);
    const [birthDate, setBirthDate] = useState(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(null);
    const [message, setMessage] = useState(null);
    const { creditCardRequest } = useCreditCardRequest(hashKey);
    const task = useCurrentTask(hashKey, taskDefinitionKey);
    const { t } = useTranslation();
    const history = useHistory();


    useEffect(() => {
        if (creditCardRequest) {
            if(creditCardRequest.birthDate){
                setBirthDate(creditCardRequest.birthDate);
            } else {
                var now = new Date();
                setBirthDate(new Date( now.getFullYear() - 18, now.getMonth(), now.getDate()));
            }
        }
    }, [creditCardRequest]);

    const handleNextPressed = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            await creditCardRequestService.setCustomerAfipData(hashKey, birthDate);
            await task.completeTask();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if(error.response) {
                const { errorCode } = error.response.data;
                if (errorCode) {
                    setMessage(t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`));
                    setOpen(true);
                }
            } else {
                setMessage(t(`APPLICATION_ERROR.${error.response.status}.DESCRIPTION`));
                setOpen(true);
            }
        }
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const hasToDisable = (dateValue, initialValue) => {
        return false;
    };

    const handleBirthDateChange = (userInput, birthDateValue) => {
        setBirthDate(birthDateValue);
        setValidForm(birthDateValue);
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    return (
        <MacroPage>
            <Box className="assetBg" style={{ zIndex: "-1" }}></Box>
            <Loader loading={loading} />
            <form onSubmit={handleNextPressed}>
                <Grid
                    item
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    style={{ marginTop: "10vh", zIndex: 100}}>
                    <Grid item xs={10} md={10}>
                        <p className="fs-12 fw-400 mb-1 uppercase">Tu tarjeta de Crédito online</p>

                        <p className="d-h1 fw-600">Nos faltaron algunos datos para poder continuar</p>
                        <div className="mt-1"></div>

                        <Grid container direction="column" justifyContent="center" alignItems="left" lg={12} sm={12} xs={12}>
                            {creditCardRequest && (
                                <Suspense fallback={<Loading />}>
                                    <div className="mt-4">
                                        <DateUserInput
                                            stepName="completeCustomerAfipData"
                                            userInput={{ name: 'PtllaFechaNacimiento', conditioningValue :{birthDate} , userInputOptions: { label: 'Fecha Nacimiento' } }}
                                            onChange={handleBirthDateChange}
                                            onDisable={hasToDisable}
                                        />
                                    </div>
                                </Suspense>
                            )}
                        </Grid>

                        <Grid container direction="row" justifyContent="center" alignItems="center" xs={12}>
                            <Grid item xs={12}>
                                <Box className="mt-5"></Box>
                            </Grid>

                            <Grid item xs={12} sm={5} md={6} lg={4}>
                                <div className="startButton center">
                                    <Button
                                        className="width-100-per width-80-res"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={!validForm}>
                                        Siguiente
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                        </Grid>
                    </Grid>
                    <Grid item xs={0} md={1}></Grid>
                </Grid>
            </form>
        </MacroPage>
    );
};
