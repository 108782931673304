import React, { useEffect, useRef } from 'react';
import { makeStyles, Modal, Link, Grid, Button } from '@material-ui/core';
import noPhoto from '../../assets/no_photo.svg';
import { TOC_HTTP, TOC_PRD, getEnv } from '../../env';

const TocAutocaptureId = (props) => {
    const { captureType, session, onHandleInputFileChange, onSuccess, onFailure } = props;
    const refContainer = useRef(null);
    console.error('session autocapture ' + session.session_id);
    useEffect(() => {
        const options = {
            session_id: session.session_id,
            document_type: props.dniType,
            document_side: captureType,
            callback: onSuccess,
            failure: onFailure,
            locale: 'es',
            http: `${getEnv(TOC_HTTP)}`,
        };

        if (getEnv(TOC_PRD) === 'true') {
            options.alt_server = 'https://prod-capture.tocws.com';
            options.url_lbac = 'https://prod-api.7oc.cl/auto-capture/data/v2';
        }

        window.TOCautocapture(refContainer.current, options);

        setOpenModalTocAlternative(false);
        timeoutHandler.current = setTimeout(() => {
            setOpenModalTocAlternative(true);
        }, 20000);

        return function cleanup() {
            var node = document.getElementById('tocAutocaptureDivID');
            while (node.hasChildNodes()) {
                node.removeChild(node.firstChild);
            }
        };
    }, [refContainer, captureType, onSuccess, onFailure, props.dniType, session.session_id]);

    const [openModalTocAlternative, setOpenModalTocAlternative] = React.useState(false);
    const timeoutHandler = useRef();

    const useStylesModal = makeStyles((theme) => ({
        paper: {
            position: 'absolute',
            width: 200,
            backgroundColor: theme.palette.background.paper,

            padding: theme.spacing(2, 4, 3),
        },
        paperToc: {
            position: 'absolute',
            width: '80%',
            backgroundColor: theme.palette.background.paper,

            padding: theme.spacing(2, 4, 3),
        },
    }));

    const useStylesModalText = makeStyles(() => ({
        content: {
            margin: '20px 0',
        },
        h1: {
            fontSize: '18px',
            fontWeight: '500',
            margin: '20px 0',
            textAlign: 'center !important',
        },
        modalText: {
            fontSize: '12px',
            color: '#000000',
            textAlign: 'justify',

            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.5,
            letterSpacing: 'normal',
        },
        modalTextToc: {
            fontSize: '16px',
            color: '#000000',
            textAlign: 'center !important',

            fontWeight: 'normal',
            lineHeight: 1.5,
        },
    }));

    const classesModalText = useStylesModalText();

    const classesModal = useStylesModal();

    const [modalStyle] = React.useState(getModalStyle);

    const handleInputFileClick = (event) => {
        hiddenFileInput.current.click();
    };
    

    const handleCloseModalTocAlternative = () => {
        setOpenModalTocAlternative(false);
        timeoutHandler.current = setTimeout(() => {
            setOpenModalTocAlternative(true);
        }, 20000);
    };

    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const hiddenFileInput = React.useRef(null);

    const bodyTocAlternative = (
        <div style={modalStyle} className={classesModal.paperToc}>
            <Grid>
                <Grid item container className={classesModalText.content} direction="column" justify="center" alignItems="center">
                    <img src={noPhoto} alt="" style={{ maxWidth: '80px', height: 'auto', marginLeft: 'auto', marginRight: 'auto' }} />
                    <h1 className={classesModalText.h1}>¿No podés capturar la foto en el marco?</h1>
                    <p className={classesModalText.modalTextToc}>
                        ¡No te preocupes! Tenemos otra solución para que captures la foto por tu cuenta. <br />
                    </p>
                </Grid>

                <Grid item container className="mt-2" justify="center" alignItems="center">
                    <Grid item xs={6} sm={6}>
                        <Link
                            component="button"
                            variant="body2"
                            style={{ color: '#07a3db', fontSize: '16px', fontWeight: 'bold' }}
                            onClick={handleCloseModalTocAlternative}
                            className="center width-100-per">
                            Seguir con Marco
                        </Link>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Button onClick={handleInputFileClick} className="width-100-per" color="primary" variant="contained">
                            Intentar por mi cuenta
                        </Button>
                        <input
                            accept="image/*"
                            ref={hiddenFileInput}
                            id="icon-button-file"
                            type="file"
                            capture="environment"
                            onChange={onHandleInputFileChange}
                            style={{ display: 'none' }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );

    return (
        <>
            <div id="tocAutocaptureDivID" className="" ref={refContainer} />
            <Modal open={openModalTocAlternative} onClose={handleCloseModalTocAlternative}>
                {bodyTocAlternative}
            </Modal>
        </>
    );
};

export default TocAutocaptureId;
