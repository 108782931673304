const { parseISO } = require('date-fns');
const { utcToZonedTime, format } = require( 'date-fns-tz');

export const addMonths = (date, months) => {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() !== d) {
        date.setDate(0);
    }
    return date;
};

const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
}

export const formatDateDDMMYYYY = (date) => {
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('/');
}


export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + padTo2Digits(d.getMonth() + 1),
        day = '' + padTo2Digits(date.getDate()),
        year = d.getFullYear();
    return [year, month, day].join('-');
}

export const calculateAge = (birthDateInput) => {

    if (!birthDateInput) {
        return null;
    }
    const birthDate = new Date(birthDateInput);
    const actualDate = new Date();
    const ageDate = new Date(actualDate - birthDate);
    // getTime() returns the number of milliseconds since January 1, 1970 00:00:00.
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const convertDateFormat = (inputDateStr) => {
    const parsedDate = parseISO(inputDateStr);
    const timeZone = 'America/Argentina/Buenos_Aires';
    const zonedDate = utcToZonedTime(parsedDate, timeZone);
    const formattedDate = format(zonedDate, "yyyy-MM-dd HH:mm:ssXXX");
    return formattedDate;
}
