import React, { useState } from 'react';
import { Button, Grid, Box, makeStyles, Hidden } from '@material-ui/core';
import useCurrentTask from '../components/bpm/useCurrentTask';
import './../styles/components/customerDataInput.scss';
import MacroStepPage from './shared/MacroStepPage';
import Toaster from '../components/alerts/Toaster';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import ContinueMobile from '../components/deviceCommunication/continueMobile';

export const StepPage = (props) => {
    const { hashKey } = props.match.params;
    const { taskDefinitionKey, title } = props;
    const [loanRequest, setLoanRequest] = useState(null);
    const [validForm, setValidForm] = useState(false);
    const [userInputs, setUserInputs] = useState({});
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [hasAccount, setHasAccount] = useState(false);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const task = useCurrentTask(hashKey, taskDefinitionKey);
    const { t } = useTranslation();
    const [modalStyle] = React.useState(getModalStyle);

    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const useStylesModal = makeStyles((theme) => ({
        paper: {
            position: 'absolute',
            width: 300,
            backgroundColor: theme.palette.background.paper,

            padding: theme.spacing(2, 2, 3),
        },
    }));

    const useStylesModalText = makeStyles(() => ({
        modalText: {
            fontSize: '11px',
            color: '#000000',
            textAlign: 'justify',

            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.5,
            letterSpacing: 'normal',
        },
    }));

    const classesModalText = useStylesModalText();
    const classesModal = useStylesModal();

    const handleNextPressed = (event) => {
        event.preventDefault();

        nextStep();
    };

    const nextStep = async () => {
        try {
            task.completeTask();
        } catch (error) {
            if (error.response && error.response.data) {
                const { properties, errorCode } = error.response.data;
                const { fieldsToCompleted } = properties;
                if (errorCode === 'ALL_FIELDS_REQUIERED_MUST_BE_COMPLETED') {
                    var msg = t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`);
                    fieldsToCompleted.map((field) => (msg += ' ' + t(`FIELDS.${field}`)));
                    setMessage(msg);
                    setOpen(true);
                }
            } else {
                console.error(error);
            }
        }
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    return (
        <MacroStepPage
            headerLead="Tu tarjeta de Crédito online"
            headerTitle={title}
            hashKey={hashKey}
            task={task}
            taskDefinitionKey={taskDefinitionKey}
            backToText={isMobile ? title : undefined}
            hideStepper={true}
            stepNumber={3}
            totalSteps={4}>
            {!isMobile ? (
                <ContinueMobile hashKey={hashKey} />
            ) : (
                <>
                    <form onSubmit={handleNextPressed}>
                        <Grid item container direction="row" justify="center" alignItems="center" xs={12}>
                            <Grid item xs={12}>
                                <Box className="mt-5"></Box>
                            </Grid>
                            Pantalla para tarea: {task.taskDefinitionKey}
                            <Grid item xs={12} sm={5} md={6} lg={4}>
                                <div className="mb-footer startButton center mb-20-web mt-20-web">
                                    <Button className="width-100-per width-80-res" type="submit" variant="contained" color="primary">
                                        Siguiente
                                    </Button>
                                    <Hidden mdUp>
                                        <Box m={3} style={{ marginBottom: '60px' }} />
                                    </Hidden>
                                </div>
                            </Grid>
                        </Grid>
                        <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                    </form>
                </>
            )}
        </MacroStepPage>
    );
};
