import React, { useState, useMemo } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MacroPage from './shared/MacroPage';
import Loader from '../components/loader/Loader';
import errorImg from '../assets/Error.svg';
import Toaster from '../components/alerts/Toaster';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { CREDIT_CARD_REQUEST_STATUS, ROUTE } from './../constants/global';

const useStyles = makeStyles((theme) => ({
    title: {
        margin: theme.spacing(5, 0, 0, 0),
        textAlign: 'center',
    },
    description: {
        margin: theme.spacing(5, 0, 5, 0),
        textAlign: 'center',
    },
    button: {
        margin: theme.spacing(5, 5, 5, 5),
    },
}));

export const NoRetryable = (props) => {
    const { errorText } = props.match.params;
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const history = useHistory();

    const buttonVisible = useMemo(() => {
        if (CREDIT_CARD_REQUEST_STATUS.EXPIRED === errorText) {
            return true;
        }
        return false;
    }, [errorText]);

    const handleToasterClose = () => {
        setOpen(false);
    };

    const handleRestart = (event) => {
        event.preventDefault();
        history.push({
            pathname: `${ROUTE.START}`,
            search: history.location.search,
        });
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    return (
        <MacroPage {...props} noBackground={true}>
            <Loader loading={loading} />
            <Grid container elevation={0} square alignItems="center">
                <Grid item xs={12} container justifyContent="center">
                    <img src={errorImg} className="mt-2" alt="" />
                </Grid>
                <Grid item container className="mt-4 center" xs={12} alignItems="center">
                    <Grid item container className="mt-4 center" xs={12} sm={10} md={3}>
                        <h2 style={{ textAlign: 'center' }}>{t('NO_RETRYABLE_ERROR.' + errorText + '.TITLE')}</h2>
                        {buttonVisible && (
                            <Grid container justifyContent="center">
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    fullWidth={true}
                                    color="primary"
                                    disableElevation={true}
                                    onClick={handleRestart}>
                                    Nueva consulta
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
        </MacroPage>
    );
};
