import React, { useEffect, useState } from 'react';
import { Grid, useTheme, Box, makeStyles, Button, Hidden } from '@material-ui/core';
import Loader from '../components/loader/Loader';
import LoanRequestResultPage from './shared/LoanRequestResultPage';
import { capitalizeText } from '../components/utils/capitalizeText';
import { useTranslation } from 'react-i18next';
import ImportantInfo from '../assets/important-info.svg';
import {buildNpsUserInformation} from '../components/utils/NpsUtils';
import NpsScriptRunner from '../components/nps/npsScriptRunner';
import useCreditCardRequest from '../hooks/useCreditCardRequest';

export const CustomerCreditCardRequestSuccessPage = (props) => {
    const { hashKey } = props.match.params;
    const { creditCardRequest } = useCreditCardRequest(hashKey);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [imgSrc, setImgSrc] = useState(null);
    const [cardMigration, setCardMigration] = useState(false);
    const theme = useTheme();
    const { t } = useTranslation();
    
    const useStyles = makeStyles(() => ({
        importantBox: {
            backgroundColor: '#EFEFEF',
            borderRadius: '20px',
            padding: '20px',
            width: '100%',
        },
    }));

    const classes = useStyles();

    useEffect(() => {
        if (creditCardRequest) {
            setCardMigration(creditCardRequest.metadata.Origin === 'macro-migration-credit-card');
            setSelectedProduct(creditCardRequest.offerOptions[0]);
            setUserInfo(buildNpsUserInformation(creditCardRequest) );
        }
    }, [creditCardRequest]);

    useEffect(() => {
        if (selectedProduct) {
            setImgSrc(require(`./../assets/tc_paquetes/${selectedProduct.productCode.toLowerCase()}.png`));
        }
    }, [selectedProduct]);

    if (!creditCardRequest) {
        return <Loader loading={true} />;
    }

    return (
        creditCardRequest && selectedProduct && imgSrc && (
            <>              
                {userInfo && <NpsScriptRunner user={userInfo} />}

                {cardMigration ?

                    <LoanRequestResultPage
                        cardMigration={true}
                        headerTitle={`Felicitaciones ${capitalizeText(creditCardRequest.customerData.CustomerFirstName)}! Hemos recibido tu solicitud`}
                        //title={`${t('CREDIT_CARD_DESCRIPTION.' + selectedProduct.productCode + '.PACKAGE')}`}
                        cardType={`${t('CREDIT_CARD_DESCRIPTION.' + selectedProduct.productCode + '.NAME')}`}
                        hideStepper={true}
                        hideQuestions={true}
                        resultImage={<img className="fit-container width-100-per" src={imgSrc} alt="Tarjeta" />}
                        className="">
                    </LoanRequestResultPage>
                    :
                    <LoanRequestResultPage
                        cardMigration={false}
                        headerTitle={`Gracias ${capitalizeText(creditCardRequest.customerData.CustomerFirstName)} :)`}
                        title={`${t('CREDIT_CARD_DESCRIPTION.' + selectedProduct.productCode + '.PACKAGE')}`}
                        cardType={`${t('CREDIT_CARD_DESCRIPTION.' + selectedProduct.productCode + '.NAME')}`}
                        hideStepper={true}
                        hideQuestions={true}
                        resultImage={<img className="fit-container width-100-per" src={imgSrc} alt="Tarjeta" />}
                        className="">
                    </LoanRequestResultPage>
                }
                
                
                <Hidden smUp>
                    <Box m={13} />
                </Hidden>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={10} lg={4}>
                        <div className="mb-footer validationIdentityButton center">
                            <Button href="https://www.macro.com.ar/beneficios?d=Any" className="width-100-per width-90-res" variant="outlined" color="primary">
                                Ver beneficios disponibles
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </>
        )
    );
};
