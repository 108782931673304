import React, { useState, useEffect, Suspense } from 'react';
import { Grid, Box, Hidden } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import NavBarSocialMedia from '../../components/navBar/NavBarSocialMedia';
import '../../styles/components/shared/macroStepPage.scss';
import Loader from '../../components/loader/Loader';
import workflowService from '../../services/workflow.service';
import Faq from '../../components/faq/faq';
import { SALESFORCE_BEACON_SCRIPT_URL, getEnv } from './../../env';

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: null,
        height: null,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
}

const MacroStepPage = (props) => {
    const size = useWindowSize();
    const { stepNumber, totalSteps, hideQuestions, taskDefinitionKey, hashKey, customFaqClass, imgFaqClass, resetStyles, noBackground } = props;
    const [loading, setLoading] = useState(false);
    const [faqs, setFaqs] = useState(null);
    const faqClass = customFaqClass ? customFaqClass : 'relative mt-faq';
    const imgFaq = imgFaqClass ? imgFaqClass : '';
    const xsContentSize = resetStyles ? 12 : 10;
    const width = size.width;
    const marginTopDevice = resetStyles ? '40px' : width > 600 ? '12vh' : '10vh';
    const breakpoint = 1300;
    const noBackgroundImg = noBackground ? true : false;

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, []);

    useEffect(() => {
        async function getFAQS(taskDefinitionKey) {
            try {
                let faqs = (await workflowService.getFAQ(taskDefinitionKey, taskDefinitionKey)).data;
                setFaqs(faqs);
            } catch (error) {
                console.error(error);
            }
        }

        if (!hideQuestions) {
            if (taskDefinitionKey) {
                getFAQS(taskDefinitionKey);
            } else {
                getFAQS('simulation');
            }
        }
    }, [taskDefinitionKey]);

    const showQuestions =
        faqs && !hideQuestions && width > breakpoint ? (
            <Grid container direction="column" style={{ top: '220px' }} justifyContent="center" alignItems="center" className="relative">
                <Grid item lg={12} md={8}>
                    <Box className={{ faqClass }} style={{ left: '0%', top: '40%' }}>
                        <Grid container direction="column" justifyContent="center" alignItems="center" className="">
                            <Box className={imgFaq}></Box>
                            <Faq faqs={faqs} />
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        ) : (
            <Box> </Box>
        );

    function ScrollToTop() {
        const { pathname } = useLocation();

        useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);

        return null;
    }
    ScrollToTop();

    return (
        <>
            <Helmet>{getEnv(SALESFORCE_BEACON_SCRIPT_URL) && <script type="text/javascript" src={getEnv(SALESFORCE_BEACON_SCRIPT_URL)} />}</Helmet>
            <Suspense fallback={<Loader loading={true} />}>
                <Loader loading={loading} />
                <NavBarSocialMedia hashKey={hashKey} taskDefinitionKey={taskDefinitionKey} />
                {!noBackgroundImg ? <Box className="assetBg"></Box> : ''}
                <Grid container className="bg macro-step-container" direction="row">
                    <Grid item container xs={12} lg={12} direction="row" className="bg">
                        <Grid
                            item
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                            style={{ marginTop: `${marginTopDevice}`, zIndex: 100 }}>
                            <Grid item container direction="column" justifyContent="center" alignItems="center" className="">
                                <Grid item container xs={xsContentSize} className="">
                                    <Grid item xs={12} className="">
                                        <p className={`fs-12 fw-400 mb-1 mt-20-web uppercase ${props.headerLeadClassName}`}>{props.headerLead}</p>

                                        <p className="d-h1 fw-600">{props.headerTitle}</p>

                                        {!props.hideStepper && (
                                            <div className="stepper mt-1">
                                                <p className="d-h4 mb-10 color-gray">
                                                    Paso {stepNumber.toString()}/{totalSteps.toString()}
                                                </p>
                                                <div className="totalStep"></div>
                                                <div className={`stepStage${((props.stepNumber * 100) / totalSteps).toString()}`}></div>
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {props?.children}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Hidden smDown>
                        <Grid item md={4} className="">
                            {showQuestions}
                        </Grid>
                    </Hidden>
                </Grid>
            </Suspense>
        </>
    );
};

export default MacroStepPage;
