import React, { useEffect, useState } from 'react';
import { Button, Grid, Box } from '@material-ui/core';
import useCurrentTask from '../components/bpm/useCurrentTask';
import identityValidationFailedImage from './../assets/identity_validation_failed.svg';
import '../styles/components/error.scss';
import MacroPage from './shared/MacroPage';
import eventTrackingService from '../services/eventtracking.service';
import Loader from '../components/loader/Loader';
import hintNoGlasses from '../assets/hint_no_glasses.svg';
import hintRotateIdCardImage from '../assets/hint_rotate_idcard.svg';
import hintCameraNoFlash from '../assets/hint_camera_no_flash.svg';

export const RestartManualDocumentsUpload = (props) => {
    const { hashKey } = props.match.params;
    const task = useCurrentTask(hashKey, props.taskDefinitionKey);
    const uploadMethod = 'manual';
    const [completingTask, setCompletingTask] = useState(false);
    const [loadingTask, setLoadingTask] = useState(true);

    useEffect(() => {
        eventTrackingService.trackEvent('Barcode_not_found', uploadMethod, null, 6);
    }, []);

    useEffect(() => {
        if (!task) {
            return;
        }
        setLoadingTask(false);
    }, [task]);

    const completeTask = async (event) => {
        event.preventDefault();
        try {
            setCompletingTask(true);
            await task.completeTask();
        } catch (error) {
        } finally {
            setCompletingTask(false);
        }
    };

    return (
        <>
            <Loader loading={loadingTask || completingTask} />
            <MacroPage hideBackground>
                <Grid container justifyContent="center">
                    <Grid item>
                        <Box className="pushDiv"></Box>
                    </Grid>
                    <Grid item xs={8}>
                        <Box display="flex" justifyContent="center">
                            <img src={identityValidationFailedImage} alt="No pudimos validar tu identidad" className="mt-3"></img>
                        </Box>
                        <p className="text-align-center fs-24 fw-600" style={{ color: '#003057', lineHeight: '30px' }}>
                            No pudimos validar tu identidad
                        </p>
                    </Grid>
                    <Grid item xs={10}>
                        <p className="text-align-center fs-16 fw-400 mt-1" style={{ color: '#003057', lineHeight: '24px' }}>
                            Tené en cuenta los siguientes consejos para tomar la foto:
                        </p>
                        <Box>
                            <ul className="hint-list mb-footer-2">
                                <li className="mt-1">
                                    <img src={hintRotateIdCardImage} style={{ padding: '5px', width: '30px' }} alt="" />
                                    <p className="fs-14" style={{ lineHeight: '24px' }}>
                                        Tomá la foto de forma vertical para poder obtener mejor los datos
                                    </p>
                                </li>
                                <li className="mt-1">
                                    <img src={hintCameraNoFlash} style={{ padding: '5px', width: '30px' }} alt="" />
                                    <p className="fs-14" style={{ lineHeight: '24px' }}>
                                        Evitá reflejos. Recomendamos no usar el flash.
                                    </p>
                                </li>
                                <li className="mt-1">
                                    <img src={hintNoGlasses} style={{ padding: '5px', width: '30px' }} alt="" />
                                    <p className="fs-14" style={{ lineHeight: '24px' }}>
                                        Evitá el uso de accesorios que tapen tu rostro como gorros, lentes o barbijo.
                                    </p>
                                </li>
                            </ul>
                        </Box>
                    </Grid>
                    <Grid item>
                        <div className="startButton center mt-4">
                            <Button
                                className="flex center mb-2 width-80-per"
                                variant="contained"
                                color="primary"
                                onClick={completeTask}
                                disabled={loadingTask || completingTask}>
                                Volver a sacar las fotos
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </MacroPage>
        </>
    );
};
