export const API_URL = 'API_URL';
export const LOCAL_URL = 'LOCAL_URL';
export const GTM_CODE = 'GTM_CODE';
export const TOC_AUTOCAPTURE_LIB = 'TOC_AUTOCAPTURE_LIB';
export const TOC_LIVENESS_LIB = 'TOC_LIVENESS_LIB';
export const TOC_HTTP = 'TOC_HTTP';
export const TOC_PRD = 'TOC_PRD';
export const ENCRYPT_MESSAGES = 'ENCRYPT_MESSAGES';
export const ENCRYPT_KEY = 'ENCRYPT_KEY';
export const GOOGLE_RECAPTCHA_KEY = 'GOOGLE_RECAPTCHA_KEY';
export const GOOGLE_MAP_API_KEY = 'GOOGLE_MAP_API_KEY';
export const SALESFORCE_BEACON_SCRIPT_URL = 'SALESFORCE_BEACON_SCRIPT_URL';
export const HOTJAR_ENABLED = 'HOTJAR_ENABLED';

export const getEnv = (key) => window.env[key];
