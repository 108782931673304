import React, { useEffect, useState } from 'react';
import { Button, Grid, Card, CardContent, Box, Link, Hidden } from '@material-ui/core';
import useCurrentTask from '../components/bpm/useCurrentTask';
import './../styles/components/customerDataInput.scss';
import MacroStepPage from './shared/MacroStepPage';
import Toaster from '../components/alerts/Toaster';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import '../styles/components/deliveryInfoInputPage.scss';
import checkIcon from '../assets/check-icon.svg';
import creditCardRequestService from '../services/creditCardRequest.service';
import useCreditCardRequest from '../hooks/useCreditCardRequest';
import Loader from '../components/loader/Loader';

export const BranchOfficeInfoInput = (props) => {
    const { hashKey } = props.match.params;
    const { taskDefinitionKey } = props;
    const [open, setOpen] = useState(false);
    const [hasAccount, setHasAccount] = useState(false);
    const [message, setMessage] = useState('');
    const { creditCardRequest } = useCreditCardRequest(hashKey);
    const task = useCurrentTask(hashKey, taskDefinitionKey);
    const [loading, setLoading] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (creditCardRequest) {
            setLoading(true);
            creditCardRequestService.hasAnyAccount(creditCardRequest.id).then((response) => {
                setHasAccount(response.data);
            }).catch((error) => {
                if (error.response && error.response.data) {
                    var msg = t(`WORKFLOW_ERROR.${error.response.data.errorCode}.DESCRIPTION`);
                    setMessage(msg);

                } else {
                    setMessage(t('WORKFLOW_ERROR.UNKNOWN_ERROR.DESCRIPTION'));
                }
                setOpen(true);
            });
            setLoading(false);
        }
    }, [creditCardRequest]);

    const handleEditBranch = (event) => {
        task.goToPreviousTask();
    }

    const handleConfirmDeliveryInfo = async () => {
        try {
            setLoading(true);
            await creditCardRequestService.setCustomerDeliveryInfo(hashKey, creditCardRequest.customerData?.branchOfficeAccountAddress, true);
            task.completeTask();
        } catch (error) {
            if (error.response && error.response.data) {
                var msg = t(`WORKFLOW_ERROR.${error.response.data.errorCode}.DESCRIPTION`);
                setMessage(msg);
            } else {
                setMessage(t('WORKFLOW_ERROR.UNKNOWN_ERROR.DESCRIPTION'));
            }
            setOpen(true);
        }
        setLoading(false);
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    return (
        <MacroStepPage
            headerLead="Tu tarjeta de crédito está casi lista"
            headerTitle="¿Dónde recibo mi tarjeta?"
            hashKey={hashKey}
            task={task}
            taskDefinitionKey={taskDefinitionKey}
            hideStepper={true}>
            {!isMobile ? (
                <ContinueMobile hashKey={hashKey} />
            ) : (
                <>
                    <Grid item container className="mb-40-res">
                        {creditCardRequest && (
                            <div className="mt-3">
                                <p className="fs-18 fw-500 mb-1">Recibirás tus tarjetas en la sucursal elegida:</p>
                                <Grid item container direction="row" xs={12}>
                                    <Loader loading={loading} />
                                    <Grid item container xs={12}>
                                        <Card className={`card-delivery-info active`}>
                                            <CardContent>
                                                <img src={checkIcon} alt="Icono de check" className="check-icon" />
                                                <p className="fs-14 fw-500">
                                                    <span>Suc. {creditCardRequest.customerData?.branchOfficeName}, {creditCardRequest.customerData?.branchOfficeAccountAddress}</span>
                                                    {'    '}


                                                </p>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>

                                <div className="selecteddiv flex center ">
                                    <Grid item container xs={12}>
                                        <Grid item xs={12}>
                                            <>
                                                {hasAccount || (creditCardRequest.customerData?.normalizationStatus && creditCardRequest.customerData?.normalizationStatus !== 'CO') &&
                                                    <p className="fw-600 color-dark-gray">
                                                        Te notificaremos por email cuando esté lista para retirar.
                                                    </p>
                                                }
                                                {hasAccount &&
                                                    <p className="fw-600 color-dark-gray">
                                                        Te notificaremos por email cuando esté lista para retirar.
                                                    </p>
                                                }
                                                {!hasAccount && (!creditCardRequest.customerData?.normalizationStatus || (creditCardRequest.customerData?.normalizationStatus && creditCardRequest.customerData?.normalizationStatus === 'CO')) &&
                                                    <>
                                                        <p className="fw-600 color-dark-gray">
                                                            Esta sucursal no cuenta con envio a domicilio. Si querés recibir tu tarjeta a domicilio probá seleccionando otra sucursal.
                                                        </p>
                                                        <Link onClick={handleEditBranch} className="fs-14 fw-700 uppercase" color="secondary">
                                                            Cambiar sucursal
                                                        </Link>
                                                    </>
                                                }
                                            </>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>

                        )}
                    </Grid>
                    <Grid item container direction="row" justifyContent="center" alignItems="center" xs={12}>
                        <Hidden smUp>
                            <Grid item xs={12}>
                                <Box className="mt-5"></Box>
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <div className="mb-footer validationIdentityButton center">
                                <Button onClick={handleConfirmDeliveryInfo} className="width-100-per width-90-res" variant="contained" color="primary">
                                    Siguiente
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                    <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                </>
            )}
        </MacroStepPage>
    );
};