import React, { useEffect } from 'react';
import deviceCommunicationService from '../services/deviceCommunication.service';
import { useHistory } from 'react-router-dom';
import Loader from '../components/loader/Loader';

export const MinifiedHashPage = (props) => {
    const { hashId } = props.match.params;
    const history = useHistory();

    useEffect(() => {
        async function redirect() {
            try {
                const minifiedHashResponse = await deviceCommunicationService.getMinifiedHash(hashId);
                const searchParams = new URLSearchParams(minifiedHashResponse.data.urlParams);
                history.push({
                    pathname: `/`,
                    search: searchParams.toString(),
                });
            } catch (error) {
                history.push({ pathname: `/error`, search: history.location.search });
            }
        }

        redirect();
    }, [hashId]);

    return (
        <div>
            <Loader loading="true" />
        </div>
    );
};
