import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import termService from './../../services/term.service';
import { isMobile } from 'react-device-detect';

const TermsModal = (props) => {
    const [open, setOpen] = useState(props.open);
    const [termsHtml, setTermsHtml] = useState('');
    const classes = useStyles();

    useEffect(() => {
        if (props.termsUrl) {
            getTermsHtml();
        }
    }, [props.termsUrl]);

    const getTermsHtml = async () => {
        try {
            const termsHtml = (await termService.getTermsHtml(props.termsUrl)).data;
            setTermsHtml(termsHtml);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (props.open === true) {
            setOpen(true);
        } else if (props.open === false) {
            setOpen(false);
        }
    }, [props.open]);

    return (
        <div>
            <Modal open={open} onClose={props.onClose} aria-labelledby="Términos y condiciones" aria-describedby="Términos y condiciones">
                <div className={classes.paper}>
                    <Grid container spacing={1} justify="space-between">
                        <Grid item xs={12}>
                            <Box overflow="visible">
                                <h2 align="center" className={classes.tTitle} paragraph={true}>
                                    {'Términos y condiciones'}
                                </h2>
                            </Box>
                        </Grid>
                        <Grid item xs={12} className={classes.body}>
                            <div dangerouslySetInnerHTML={{ __html: termsHtml }}></div>
                        </Grid>
                        <Grid item container direction="row" justify="center" alignItems="center" xs={12} className={classes.actionBar}>
                            <Button
                                variant="contained"
                                disableElevation
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onReject();
                                }}
                                color="default"
                                className={classes.defaultButton}>
                                Rechazar
                            </Button>

                            <Button
                                variant="contained"
                                disableElevation
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onAccept();
                                }}
                                color="primary"
                                className={classes.primaryButton}
                                autoFocus>
                                Aceptar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '80%' : '35%',
        maxHeight: 520,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        outline: 0,
        borderRadius: 8,
    },
    body: {
        height: 380,

        overflowY: 'auto',
        fontSize: '14px',
        color: '#212121',
    },
    actionBar: {
        paddingTop: 30,
        paddingBottom: 10,
    },
    defaultButton: {
        marginLeft: 15,
        marginRight: 15,
        color: '#092f57',
        backgroundColor: '#ffffff',
        borderColor: '#092f57',
        borderWidth: '1px',
        borderStyle: 'solid',
        width: 220,
        fontSize: '14px',
        marginBottom: 10,
    },
    primaryButton: {
        marginLeft: 15,
        marginRight: 15,
        color: '#ffffff',
        backgroundColor: '#092f57',
        width: 220,
        fontSize: '14px',
    },
    title: {
        fontSize: '18px',
        color: '#092f57',
    },
}));

export default TermsModal;
