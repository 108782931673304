import React from 'react';
import '../../styles/components/documentsUpload.scss';
import hintBlur from '../../assets/hint-blur.svg';
import hintCameraPermissions from '../../assets/heroicons_camera.svg';
import hintFace from '../../assets/hint-face.svg';
import hintLight from '../../assets/hint-light.svg';
import hintWarning from '../../assets/hint-warning.svg';

export const DocumentsUploadHints = ({ selfie = false }) => {
    return (
        <div>
            <ul className="hint-list">
                <li>
                    <img src={hintLight} alt="" />
                    Buscá un lugar bien iluminado
                </li>
                {!selfie && (
                    <li>
                        <img src={hintWarning} alt="" />
                        Utilizá tu último DNI vigente
                    </li>
                )}
                {!selfie && (
                    <li>
                        <img src={hintBlur} alt="" />
                        Asegurate que los datos estén legibles
                    </li>
                )}
                {!selfie && (
                    <li>
                        <img src={hintCameraPermissions} alt="" />
                        Aprobá los permisos de la cámara
                    </li>
                )}
                {selfie && (
                    <li>
                        <img src={hintFace} alt="" />
                        Despejá tu rostro
                    </li>
                )}
                {selfie && (
                    <li>
                        <img src={hintWarning} alt="" />
                        Ubicá tu rostro dentro de la máscara y seguí las indicaciones
                    </li>
                )}
            </ul>
        </div>
    );
};
